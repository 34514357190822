.l-work {
	display: flex;
	flex-direction: column;
}

.l-work__wrapper {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 100vh;
}

.l-work--group .l-work__wrapper {
	display: block;
	position: relative;
}

.l-work__mobile-hit-wrapper {
	pointer-events: none;
	position: fixed;
	top: 0;
	right: 0;
	width: 100%;
	height: 100vh;
	z-index: 10;
}

.l-work__mobile-hit {
	border-bottom: 1px solid var(--color-pink);
	padding-top: 0;
	position: absolute;
	top: 19.5rem;
	width: 100%;
}

@media (--mq-from-small) {
	.l-work__mobile-hit {
		display: none;
	}
}

.l-work--group .l-work__page-intro {
	margin-bottom: 2rem;
	min-height: 8.5625rem;
}

.l-work__title {
	color: var(--color-orange);
}

.l-work--group .l-work__title {
	box-sizing: border-box;
	margin-bottom: 50vh;
	pointer-events: none;
	position: sticky;
	top: 0;
	z-index: 10;
}

@media (--mq-up-to-small) {
	.l-work--group .l-work__title {
		background-color: var(--color-white);
		padding-top: 0.625rem;
		padding-bottom: 0.625rem;
	}
}

@media (--mq-from-small) {
	.l-work--group .l-work__title {
		margin-bottom: calc(100vh - 10.5625rem);
		top: 10.5625rem;
	}
}

@media (--mq-from-medium) {
	.l-work--group .l-work__title {
		margin-bottom: calc(100vh - (124px + 5rem));
		top: calc(124px + 5rem);
	}
}

.l-work__dashboard {
	margin-bottom: var(--space-medium);
}

@media (--mq-from-medium) {
	.l-work__dashboard {
		margin-bottom: var(--space-x-large);
	}
}

.l-work__dashboard__container {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
}

.l-work__dashboard__years {
	flex-basis: 64px;
	flex-grow: 0;
	flex-shrink: 0;
	margin-right: 1.25rem;
}

@media (--mq-from-small) {
	.l-work__dashboard__years {
		flex-basis: 18%;
	}
}

.l-work__dashboard__types {
	flex-grow: 1;
	flex-shrink: 0;
}

@media (--mq-from-small) {
	.l-work__dashboard__types {
		flex-basis: 45%;
		flex-grow: 0;
	}
}

.l-work__content {
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	justify-content: center;
}

.l-work--group .l-work__content {
	margin-top: -50vh;
}

@media (--mq-up-to-xsmall) {
	.l-work--group .l-work__content {
		padding-top: 80vw;
	}
}

@media (--mq-up-to-small) {
	.l-work--group .l-work__content {
		padding-top: 15rem;
	}
}

@media (--mq-from-small) {
	.l-work--group .l-work__content {
		margin-top: calc(-1 * (100vh - 10.5625rem));
	}
}

@media (--mq-from-medium) {
	.l-work--group .l-work__content {
		margin-top: calc(-1 * (100vh - (124px + 5rem)));
	}
}

.l-work__content__wrapper {
	display: flex;
	flex-grow: 1;
}

@media (--mq-up-to-small) {
	.l-work__content__wrapper {
		flex-direction: column;
	}
}

@media (--mq-from-small) {
	.l-work__content__wrapper {
		align-items: stretch;
	}
}

@media (--mq-from-small) {
	.l-work__content__column {
		flex-basis: 50%;
		flex-grow: 0;
		flex-shrink: 0;
	}
}

.l-work__content__column__container {
	box-sizing: border-box;
	padding: 1.25rem;
	height: 100%;
}

@media (--mq-from-medium) {
	.l-work__content__column__container {
		padding: 1rem var(--space-medium);
	}
}

@media (--mq-up-to-small) {
	.l-work__content__column__container.l-work__content__column__container--media {
		padding: 0;
	}
}

@media (--mq-from-small) {
	.l-work__content__media-wrapper {
		margin: 0 auto;
		overflow: hidden;
		position: relative;
		width: 100%;
		height: 100%;
	}
}

@media (--mq-from-large) {
	.l-work__content__media-wrapper {
		width: calc(100% - 16rem);
	}
}

@media (--mq-from-small) {
	.l-work__content__media {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 100%;
		height: 100%;
	}
}

.l-work__content__media__item {
	display: block;
	width: 100%;
}

@media (--mq-from-small) {
	.l-work__content__media__item {
		margin-top: 0;
		margin-bottom: 0;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		max-width: none;
		height: auto;
		z-index: 0;
	}

	@supports (object-fit: contain) {
		.l-work__content__media__item {
			object-fit: contain;
			object-position: top;
			max-width: none;
			height: 100%;
		}
	}
}

.l-work__link {
	opacity: 0.55;
	padding-top: 1.25rem;
	padding-bottom: 1.25rem;
}

@media (--mq-from-medium) {
	.l-work__link {
		padding-top: var(--space-medium);
		padding-bottom: var(--space-medium);
	}
}

@media (--mq-up-to-small) {
	.l-work--parent .l-work__link {
		display: none;
	}
}

.l-work__link.l-work__link--mobile-only {
	display: none;
}

@media (--mq-up-to-small) {
	.l-work__link.l-work__link--mobile-only {
		display: block;
		padding-top: var(--space-medium);
		padding-bottom: 0;
	}
}

.l-work--group .l-work__link {
	position: sticky;
	bottom: 0;
	z-index: 10;
}

@media (prefers-contrast: more) {
	.l-work__link {
		opacity: 0.42;
	}
}
