@media (--mq-from-small) {
	.media-group {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}
}

@media (--mq-up-to-small) {
	.media-group__item:not(last-child) {
		margin-bottom: 2rem;
	}
}

@media (--mq-from-small) {
	.media-group__item {
		flex-basis: 45%;
		flex-grow: 0;
		flex-shrink: 0;
	}
}
