.stream {
	list-style: none;
	margin: 0;
	padding: 0;
	max-width: 1180px;
}

.stream__item {
	margin-bottom: 1rem;
}

@media (width >= 64rem) {
	.stream__item {
		margin-bottom: 2rem;
	}
}

@media (width >= 105rem) {
	.stream__item {
		margin-bottom: 3.25rem;
	}
}

.stream__item:last-child {
	margin-bottom: 0;
}

.stream__link {
	color: var(--color-black);
	display: inline-block;
	text-decoration: none;
	transition: opacity 96ms linear;
}

.stream__item--disabled .stream__link {
	color: var(--color-grey-medium);
	pointer-events: none;
	text-decoration: underline;
	text-underline-offset: 2px;
}

.stream__link[data-translated-title]::after {
	content: attr(data-translated-title);
	display: none;
}

.stream__item.is-active .stream__link[data-translated-title]::after {
	display: inline;
}

.stream__link:hover {
	opacity: 0.6;
}

.stream__item.is-active .stream__link[data-translated-title] .stream__link__title {
	display: none;
}
