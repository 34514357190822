.l-artist {
	position: relative;
}

.l-artist__page-intro {
	width: 100%;
}

.l-artist__page-intro.l-artist__page-intro--absolute {
	position: absolute;
	z-index: 2;
}

.l-artist__page-intro p > a:not([class]) {
	color: currentColor;
}
