.life-blend-tool {
	/* empty */
}

.life-blend-tool__row {
	display: flex;
}

.life-blend-tool__tool-wrapper {
	margin: 0 auto 0 auto;
	padding: 1rem;
	max-width: 100%;
}

@media (--mq-from-small) {
	.life-blend-tool__tool-wrapper {
		padding: 3rem;
		max-width: 70%;
	}
}

@media (--mq-from-medium) {
	.life-blend-tool__tool-wrapper {
		max-width: 50%;
	}
}

.life-blend-tool__controls-container {
	display: flex;
	flex-direction: column;
	padding-top: 3rem;
}

@media (--mq-from-medium) {
	.life-blend-tool__controls-container {
		flex-direction: row;
	}
}

.life-blend-tool__controls-column-1 {
	min-width: 50%;
}

.life-blend-tool__controls-column-2 {
	display: block;
}

.life-blend-tool__control {
	padding-bottom: 1rem;
}

@media (--mq-from-small) {
	.life-blend-tool__control {
		padding-bottom: 0;
	}
}

.life-blend-tool__control label {
	color: var(--black);
	display: inline-block;
	font-family: var(--font-mono);
	font-size: 0.75rem;
	font-stretch: normal;
	font-style: normal;
	font-weight: 400;
	letter-spacing: normal;
	line-height: normal;
	text-transform: uppercase;
	min-width: 160px;
}

.life-blend-tool__control select {
	font-size: 0.75rem;
	min-width: 120px;
	height: 1.5rem;
}

.life-blend-tool__control select:focus:not(:focus-visible) {
	outline: 0;
}

.life-blend-tool__control input:focus:not(:focus-visible) {
	outline: 0;
}

.life-blend-tool__image {
	width: 100%;
	height: auto;
}

.life-blend-tool__image[hidden] {
	display: none;
}

#life-blend-tool-filter {
	transition: opacity 0.5s ease-out;
}

#life-blend-tool-filter[disabled] {
	opacity: 0.4;
}
