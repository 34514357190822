.convincer {
	background-color: white;
	list-style: none;
	margin: 0;
	padding: 0;
	position: relative;
}

@media (--mq-from-small) {
	.convincer {
		display: flex;
		align-items: stretch;
	}
}

.is-showing-site-navigation__modal .convincer {
	filter: var(--site-navigation-active-background-blur);
}

@media (--mq-from-small) {
	.convincer__item {
		flex-grow: 1;
		min-height: 13.75vw;
	}

	.convincer__item:not(:only-child) {
		flex-basis: 50%;
		flex-grow: 0;
		flex-shrink: 0;
	}
}

@media (--mq-from-small) {
	.convincer--rich .convincer__item {
		min-height: 8.6vw;
	}
}

.is-showing-site-navigation__modal .convincer__item {
	margin-top: -8px;
	padding-top: 8px;
}

.convincer__item__link {
	box-sizing: border-box;
	color: currentColor;
	display: flex;
	overflow: hidden;
	padding: 3.125rem 1.25rem;
	justify-content: center;
	align-items: center;
	position: relative;
	text-align: center;
	text-decoration: none;
}

@media (--mq-from-small) {
	.convincer__item__link {
		padding: 5rem 2.5rem;
		height: 100%;
	}
}

@media (--mq-from-medium) {
	.convincer__item__link {
		padding: 5.875rem 3rem;
	}
}

@media (width >= 80rem) {
	.convincer__item__link {
		padding: 6.875rem 3.5rem;
	}
}

.convincer--rich .convincer__item__link {
	background-color: var(--color-black);
	color: white;
	flex-direction: column;
	padding: 1rem;
	justify-content: space-between;
	align-items: flex-start;
	text-align: left;
}

@media (--mq-from-medium) {
	.convincer--rich .convincer__item__link {
		padding: 1.25rem;
	}
}

@media (width >= 80rem) {
	.convincer--rich .convincer__item__link {
		padding: 1.75rem;
	}
}

.convincer--rich .convincer__item--light .convincer__item__link {
	background-color: var(--color-white);
	color: black;
}

.convincer__item__background {
	overflow: hidden;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
}

.convincer__item__background__media {
	opacity: 1;
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	transition: opacity 96ms linear;
	width: 100%;
	height: auto;
}

@supports (object-fit: cover) {
	.convincer__item__background__media {
		object-fit: cover;
		object-position: center center;
		top: 0;
		transform: none;
		height: 100%;
	}
}

.convincer__item__link:hover .convincer__item__background__media {
	opacity: 0.7;
}

.convincer__item__label,
.convincer__item__title {
	position: relative;
	z-index: 2;
}

.convincer__item__label {
	font-size: 0.75rem;
}

.convincer__item__title {
	font-size: 1.75rem;
	font-weight: var(--font-weight-light);
	line-height: 1.2;
}

@media (--mq-from-small) {
	.convincer__item__title {
		font-size: 2rem;
	}
}

@media (--mq-from-wide) {
	.convincer__item__title {
		font-size: 2.25rem;
	}
}

.convincer--rich .convincer__item__title {
	font-size: 1.375rem;
	margin-top: 3.375rem;
}

@media (--mq-from-small) {
	.convincer--rich .convincer__item__title {
		font-size: 1.5rem;
	}
}

@media (--mq-from-wide) {
	.convincer--rich .convincer__item__title {
		font-size: 1.75rem;
	}
}

.convincer__item__title > span {
	border-bottom-color: rgb(0 0 0 / 0);
	border-bottom-style: solid;
	border-bottom-width: 1px;
	transition: border-bottom-color 96ms linear;
}

.convincer--rich .convincer__item:not(.convincer__item--light) .convincer__item__title > span {
	border-bottom-color: rgb(255 255 255 / 0);
}

.convincer__item__link:hover .convincer__item__title > span {
	border-bottom-color: rgb(0 0 0 / 0.3);
}

.convincer--rich .convincer__item:not(.convincer__item--light) .convincer__item__link:hover .convincer__item__title > span {
	border-bottom-color: rgb(255 255 255 / 0.5);
}
