.gradients {
	position: relative;
	height: 100px;
}

/* mq-from-xsmall */
@media (--mq-from-xsmall) {
	.gradients {
		height: 150px;
	}
}

/* mq-from-wide */
@media (--mq-from-wide) {
	.gradients {
		height: 200px;
	}
}

.gradients::before {
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100px;
}

/* mq-from-xsmall */
@media (--mq-from-xsmall) {
	.gradients::before {
		height: 150px;
	}
}

/* mq-from-wide */
@media (--mq-from-wide) {
	.gradients::before {
		height: 200px;
	}
}

/* #region Sheltr */
.gradients--sheltr-black::before {
	background: linear-gradient(to var(--direction), rgb(0 0 0 / 0) 0%, rgb(0 0 0 / 0.013) 8.1%, rgb(0 0 0 / 0.049) 15.5%, rgb(0 0 0 / 0.104) 22.5%, rgb(0 0 0 / 0.175) 29%, rgb(0 0 0 / 0.259) 35.3%, rgb(0 0 0 / 0.352) 41.2%, rgb(0 0 0 / 0.45) 47.1%, rgb(0 0 0 / 0.55) 52.9%, rgb(0 0 0 / 0.648) 58.8%, rgb(0 0 0 / 0.741) 64.7%, rgb(0 0 0 / 0.825) 71%, rgb(0 0 0 / 0.896) 77.5%, rgb(0 0 0 / 0.951) 84.5%, rgb(0 0 0 / 0.987) 91.9%, rgb(0 0 0 / 1) 100%);
}

.gradients--sheltr-blue::before {
	background: linear-gradient(to var(--direction), rgb(77 103 241 / 0) 0%, rgb(77 103 241 / 0.013) 8.1%, rgb(77 103 241 / 0.049) 15.5%, rgb(77 103 241 / 0.104) 22.5%, rgb(77 103 241 / 0.175) 29%, rgb(77 103 241 / 0.259) 35.3%, rgb(77 103 241 / 0.352) 41.2%, rgb(77 103 241 / 0.45) 47.1%, rgb(77 103 241 / 0.55) 52.9%, rgb(77 103 241 / 0.648) 58.8%, rgb(77 103 241 / 0.741) 64.7%, rgb(77 103 241 / 0.825) 71%, rgb(77 103 241 / 0.896) 77.5%, rgb(77 103 241 / 0.951) 84.5%, rgb(77 103 241 / 0.987) 91.9%, rgb(77 103 241 / 1) 100%);
}

.gradients--sheltr-grey::before {
	background: linear-gradient(to var(--direction), rgb(232 229 220 / 0) 0%, rgb(232 229 220 / 0.013) 8.1%, rgb(232 229 220 / 0.049) 15.5%, rgb(232 229 220 / 0.104) 22.5%, rgb(232 229 220 / 0.175) 29%, rgb(232 229 220 / 0.259) 35.3%, rgb(232 229 220 / 0.352) 41.2%, rgb(232 229 220 / 0.45) 47.1%, rgb(232 229 220 / 0.55) 52.9%, rgb(232 229 220 / 0.648) 58.8%, rgb(232 229 220 / 0.741) 64.7%, rgb(232 229 220 / 0.825) 71%, rgb(232 229 220 / 0.896) 77.5%, rgb(232 229 220 / 0.951) 84.5%, rgb(232 229 220 / 0.987) 91.9%, rgb(232 229 220 / 1) 100%);
}

.gradients--sheltr-orange::before {
	background: linear-gradient(to var(--direction), rgb(255 110 50 / 0) 0%, rgb(255 110 50 / 0.013) 8.1%, rgb(255 110 50 / 0.049) 15.5%, rgb(255 110 50 / 0.104) 22.5%, rgb(255 110 50 / 0.175) 29%, rgb(255 110 50 / 0.259) 35.3%, rgb(255 110 50 / 0.352) 41.2%, rgb(255 110 50 / 0.45) 47.1%, rgb(255 110 50 / 0.55) 52.9%, rgb(255 110 50 / 0.648) 58.8%, rgb(255 110 50 / 0.741) 64.7%, rgb(255 110 50 / 0.825) 71%, rgb(255 110 50 / 0.896) 77.5%, rgb(255 110 50 / 0.951) 84.5%, rgb(255 110 50 / 0.987) 91.9%, rgb(255 110 50 / 1) 100%);
}

.gradients--sheltr-yellow::before {
	background: linear-gradient(to var(--direction), rgb(245 217 75 / 0) 0%, rgb(245 217 75 / 0.013) 8.1%, rgb(245 217 75 / 0.049) 15.5%, rgb(245 217 75 / 0.104) 22.5%, rgb(245 217 75 / 0.175) 29%, rgb(245 217 75 / 0.259) 35.3%, rgb(245 217 75 / 0.352) 41.2%, rgb(245 217 75 / 0.45) 47.1%, rgb(245 217 75 / 0.55) 52.9%, rgb(245 217 75 / 0.648) 58.8%, rgb(245 217 75 / 0.741) 64.7%, rgb(245 217 75 / 0.825) 71%, rgb(245 217 75 / 0.896) 77.5%, rgb(245 217 75 / 0.951) 84.5%, rgb(245 217 75 / 0.987) 91.9%, rgb(245 217 75 / 1) 100%);
}
/* #endregion Sheltr */
