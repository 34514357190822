@media (--mq-up-to-small) {
	.folder {
		box-sizing: border-box;
		display: block;
		width: 200px;
	}
}

@media (--mq-from-small) {
	.folder {
		padding: 0.75rem;
		padding-bottom: 3.75rem;
		width: 155px;
		height: 120px;
	}
}

.folder.is-dragged {
	z-index: var(--z-index-folder);
}

@media (--mq-up-to-small) {
	.folder.is-active {
		background-color: var(--color-grey);
	}
}

.folder:focus {
	outline: none;
}

@media (--mq-from-wide) {
	.l-folders__desktop > .folder {
		padding-bottom: 5.625rem;
	}
}

@media (--mq-from-small) {
	.window__content > .folder {
		box-sizing: border-box;
		width: calc(100% / 3);
	}
}

mr-file-toggle,
mr-folder-link {
	display: block;
}

@media (--mq-up-to-small) {
	.folder__link {
		padding: 4px 20px;
	}
}

.folder__link {
	display: block;
	text-decoration: none;
	width: 100%;
	height: 100%;
}

.folder__link:hover {
	cursor: pointer;
}

.folder__label {
	color: var(--color-black);
	font-size: 12px;
}

@media (--mq-up-to-small) {
	.folder__label {
		display: inline-block;
		margin-top: 2px;
		margin-left: 2px;
		vertical-align: middle;
		width: calc(100% - 24px);
	}
}

@media (--mq-from-small) {
	.folder__label {
		display: block;
		height: 43px;
	}
}

.folder__label p {
	font-size: inherit;
	padding: 0;
}

@media (--mq-up-to-small) {
	.folder__label p {
		text-overflow: ellipsis;
		white-space: nowrap;
	}
}

@media (--mq-from-small) {
	.folder__label p {
		margin: 18px 0 0;
		overflow-wrap: break-word;
		text-align: center;
	}
}

.folder:focus .folder__label,
.is-dragged .folder__label {
	text-shadow: 0 4px 5px rgb(0 0 0 / 0.1);
}

.folder__icon {
	box-sizing: border-box;
	overflow: hidden;
	position: relative;
}

@media (--mq-up-to-small) {
	.folder__icon {
		display: inline-block;
		vertical-align: middle;
		width: 16px;
		height: 16px;
	}
}

@media (--mq-from-small) {
	.folder__icon {
		width: 100%;
		height: 77px;
	}
}

.folder--folder .folder__icon,
.folder--text .folder__icon {
	padding-top: 4px;
}

@media (--mq-from-small) {
	.folder--folder .folder__icon,
	.folder--text .folder__icon {
		padding-top: 12px;
	}
}

.folder--image .folder__icon {
	padding-top: 4px;
}

.folder__icon__image {
	display: block;
	margin-right: auto;
	margin-left: auto;
	position: relative;
	width: auto;
	max-width: none;
	height: 100%;
}

@supports (object-fit: contain) {
	.folder__icon__image {
		object-fit: contain;
		width: 100%;
	}
}

.folder--image .folder__icon__image {
	box-shadow: 3px 3px 0 0 rgb(0 0 0 / 0.2);
	margin-right: auto;
	margin-left: auto;
}

.folder--text .folder__icon__image {
	background-color: var(--color-white);
	fill: var(--color-grey);
}

.folder--folder .folder__icon__image {
	background-image: linear-gradient(310deg, #ffd1a4, #ffff99);
	border: solid 1px #d9d96b;
	border-radius: 1px;
	box-shadow: 1px 1px 0 0 rgb(0 0 0 / 0.1);
	width: 14px;
	height: 10px;
}

@media (--mq-from-small) {
	.folder--folder .folder__icon__image {
		width: 92px;
		height: 63px;
	}
}

.folder--folder .folder__icon__image::after {
	border-right: 2px solid transparent;
	border-bottom: 2px solid #ffff99;
	border-left: 2px solid transparent;
	content: "";
	display: block;
	position: absolute;
	top: -2px;
	left: 1px;
	width: 6px;
}

@media (--mq-from-small) {
	.folder--folder .folder__icon__image::after {
		border-right: 9px solid transparent;
		border-bottom: 9px solid #ffff99;
		border-left: 9px solid transparent;
		top: -10px;
		width: 38px;
	}
}

.folder--folder .folder__icon__image::before {
	border-right: 3px solid transparent;
	border-bottom: 3px solid #d9d96b;
	border-left: 3px solid transparent;
	content: "";
	display: block;
	position: absolute;
	top: -3px;
	left: 0;
	width: 6px;
}

@media (--mq-from-small) {
	.folder--folder .folder__icon__image::before {
		border-right: 10px solid transparent;
		border-bottom: 10px solid #d9d96b;
		border-left: 10px solid transparent;
		top: -11px;
		width: 38px;
	}
}

/* Move up a couple of pixels and throw shadow to give it depth when dragging */

.folder:focus .folder__icon__image,
.folder.is-dragged > * > .folder__link > .folder__icon > .folder__icon__image {
	box-shadow: none;
	transform: translateY(-2px);
}

.folder.folder--folder:focus .folder__icon__image,
.folder.folder--folder.is-dragged > * > .folder__link > .folder__icon > .folder__icon__image {
	box-shadow: 0 4px 5px rgb(0 0 0 / 0.18);
}
