.l-fullscreen {
	position: relative;
	width: 100%;
	min-height: 100vh;
	z-index: 1;
}

.l-fullscreen__button {
	position: absolute;
	text-align: right;
	top: 50%;
	right: 1.25rem;
	transform: translateY(-50%);
	z-index: 2;
}

@media (--mq-from-medium) {
	.l-fullscreen__button {
		right: var(--space-medium);
	}
}

@media (min-width: 120em) {
	.l-fullscreen__button.wrapper--large {
		right: calc((100% - 107.5rem) / 2);
	}
}

.l-fullscreen__background {
	background-color: #d8d8d8;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
}

.l-fullscreen__background__media {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: auto;
	max-width: none;
	height: 100%;
}

@supports (object-fit: cover) {
	.l-fullscreen__background__media {
		object-fit: cover;
		object-position: center;
		width: 100%;
	}
}
