@media (width >= 48rem) {
	.media-columns.media-columns--count-2,
	.media-columns.media-columns--count-3,
	.media-columns.media-columns--count-4 {
		display: flex;
		align-items: stretch;
	}
}

@media (width >= 48rem) {
	.media-columns--count-2 .media-columns__item,
	.media-columns--count-3 .media-columns__item,
	.media-columns--count-4 .media-columns__item {
		flex-grow: 1;
		flex-shrink: 1;
	}
}

@media (width >= 48rem) {
	.media-columns--count-2 .media-columns__item:not(:last-child):not(.media-columns__item--none),
	.media-columns--count-3 .media-columns__item:not(:last-child):not(.media-columns__item--none),
	.media-columns--count-4 .media-columns__item:not(:last-child):not(.media-columns__item--none) {
		border-right: 1px solid rgb(0 0 0 / 0.1);
	}
}

@media (width >= 48rem) {
	.media-columns--count-2 .media-columns__item {
		flex-basis: 50%;
	}
}

@media (width >= 48rem) {
	.media-columns--count-3 .media-columns__item {
		flex-basis: 33.333%;
	}
}

@media (width >= 48rem) {
	.media-columns--count-4 .media-columns__item {
		flex-basis: 25%;
	}
}

.media-columns__item__wrapper {
	box-sizing: border-box;
}

@media (width >= 48rem) {
	.media-columns--count-2 .media-columns__item__wrapper,
	.media-columns--count-3 .media-columns__item__wrapper,
	.media-columns--count-4 .media-columns__item__wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100%;
	}
}

.media-columns__item--small .media-columns__item__wrapper,
.media-columns__item--default .media-columns__item__wrapper {
	padding: 2rem;
}

@media (width >= 48rem) {
	.media-columns__item--small .media-columns__item__wrapper,
	.media-columns__item--default .media-columns__item__wrapper {
		padding: 3rem;
	}
}

@media (width >= 86.25rem) {
	.media-columns__item--default .media-columns__item__wrapper {
		padding: 5rem;
	}
}

@media (width >= 105rem) {
	.media-columns__item--default .media-columns__item__wrapper {
		padding: 8rem;
	}
}

@media (width < 48rem) {
	.media-columns--count-2 .media-columns__item:not(:last-child) .media-columns__item__wrapper,
	.media-columns--count-3 .media-columns__item:not(:last-child) .media-columns__item__wrapper,
	.media-columns--count-4 .media-columns__item:not(:last-child) .media-columns__item__wrapper {
		padding-bottom: 0;
	}
}

@media (width < 48rem) {
	.media-columns__item:not(.media-columns__item--none) + .media-columns__item--none .media-columns__item__wrapper {
		padding-top: 2rem;
	}
}

@media (width >= 48rem) {
	.section--no-padding-bottom .media-columns__item__wrapper {
		padding-bottom: 0;
	}
}

@media (width < 48rem) {
	.section--no-padding-bottom .media-columns__item__wrapper:last-child {
		padding-bottom: 0;
	}
}
