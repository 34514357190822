.page-intro {
	box-sizing: border-box;
	padding-top: var(--space-small);
	padding-bottom: var(--space-small);
	min-height: 10rem;
}

@media (--mq-from-small) {
	.page-intro {
		min-height: 16rem;
	}
}

@media (--mq-from-medium) {
	.page-intro {
		padding-top: var(--space-medium);
		padding-bottom: var(--space-medium);
		min-height: 14rem;
	}
}

@media (--mq-from-wide) {
	.page-intro {
		min-height: 12.5rem;
	}
}

.l-work .page-intro {
	min-height: 0;
}

.page-intro__wrapper {
	max-width: calc(100% - var(--space-medium));
}

@media (--mq-from-xsmall) {
	.page-intro__wrapper {
		max-width: 24rem;
	}
}

@media (--mq-from-medium) {
	.page-intro__wrapper {
		max-width: 28rem;
	}
}

@media (--mq-from-wide) {
	.page-intro__wrapper {
		max-width: 42rem;
	}
}
