.half-half {
	display: flex;
}

@media (width < 48rem) {
	.half-half {
		flex-direction: column;
	}
}

@media (width >= 48rem) {
	.half-half {
		align-items: stretch;
	}
}

.half-half.half-half--full_height {
	min-height: 100vh;
}

.half-half__column {
	box-sizing: border-box;
}

@media (width >= 48rem) {
	.half-half__column {
		flex-basis: 50%;
		flex-grow: 0;
		flex-shrink: 0;
	}
}

@media (width >= 48rem) {
	.half-half:not(.half-half--hide-borders) .half-half__column:not(:last-child):not(.half-half__column--right),
	.half-half:not(.half-half--hide-borders) .half-half__column.half-half__column--right + .half-half__column:last-child {
		border-right: 1px solid rgb(0 0 0 / 0.1);
	}
}

@media (width >= 48rem) {
	.half-half__column.half-half__column--right {
		order: 1;
	}
}

@media (width < 48rem) {
	.half-half__column.half-half__column--bottom {
		order: 1;
	}
}

@media (width >= 48rem) {
	.half-half--vertical-center .half-half__column,
	.half-half--vertical-bottom .half-half__column {
		display: flex;
		flex-direction: column;
	}
}

@media (width >= 48rem) {
	.half-half--vertical-center .half-half__column {
		justify-content: center;
	}
}

@media (width >= 48rem) {
	.half-half--vertical-bottom .half-half__column {
		justify-content: flex-end;
	}
}

.half-half__row {
	box-sizing: border-box;
	padding: 2rem;
}

@media (width >= 48rem) {
	.half-half__row {
		padding: 3rem;
	}
}

@media (width >= 86.25rem) {
	.half-half__row {
		padding: 5rem;
	}
}

@media (width >= 105rem) {
	.half-half__row {
		padding: 8rem;
	}
}

@media (width >= 48rem) {
	.half-half__row:not(:only-child):not(:last-child) {
		border-bottom: 1px solid rgb(0 0 0 / 0.1);
	}
}

@media (width < 48rem) {
	.half-half__column:first-child .half-half__row,
	.half-half__column:last-child .half-half__row:not(:last-child) {
		padding-bottom: 0;
	}
}

.half-half__row.half-half__row--brush-text {
	position: relative;
}

@media (width >= 48rem) {
	.half-half__row.half-half__row--sticky {
		position: sticky;
		top: 0;
	}
}

@media (width >= 48rem) {
	.half-half__row.half-half__row--full_height {
		display: flex;
		flex-direction: column;
		justify-content: center;
		min-height: 100vh;
	}
}

@media (width < 48rem) {
	.half-half__row.half-half__row--full_width {
		padding: 0;
	}
}

@media (width >= 86.25rem) {
	.half-half__row.half-half__row--wrapper-small {
		padding: 3rem;
	}
}

.half-half__row.half-half__row--wrapper-none {
	padding: 0;
}

@media (width < 48rem) {
	.half-half__row:not(.half-half__row--wrapper-none) + .half-half__row.half-half__row--wrapper-none,
	.half-half__column:last-child .half-half__row.half-half__row--wrapper-none:last-child {
		padding-top: 2rem;
	}
}

@media (width >= 48rem) {
	.section--no-padding-bottom .half-half__row:last-child {
		padding-bottom: 0;
	}
}

@media (width < 48rem) {
	.section--no-padding-bottom .half-half__column:last-child .half-half__row:last-child {
		padding-bottom: 0;
	}
}

.half-half__row--brush-text .half-half__row__text {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	max-width: 360px;
	z-index: 2;
}

.half-half__row__brush-nugget-wrapper {
	display: block;
	margin: 0 auto;
	padding: 2rem 0;
	width: 100%;
	max-width: 768px;
}

.half-half__row__brush-nugget {
	display: block;
	overflow: hidden;
	padding-top: 117.1875%;
	position: relative;
}

.half-half__row__brush-nugget .brush-nugget {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: calc(100% + 4rem);
}

@media (width >= 48rem) {
	.half-half__row__brush-nugget .brush-nugget {
		width: calc(100% + 6rem);
	}
}

@media (width >= 86.25rem) {
	.half-half__row__brush-nugget .brush-nugget {
		width: calc(100% + 10rem);
	}
}
