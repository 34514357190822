:root {
	/* Colors. */
	--color-black: #000000;
	--color-grey: #dddddd;
	--color-grey-light: #f9f9f9;
	--color-grey-medium: #737373;
	--color-orange: rgb(255 93 16);
	--color-pink: #ffc0cb;
	--color-purple: #9013fe;
	--color-white: #ffffff;

	/* Fonts and font config. */
	--font-impact: "MaisonNeue", sans-serif;
	--font-mono: "MaisonNeueMono", monospace;
	--font-sans: "MaisonNeue", sans-serif;
	--font-serif: "MaisonNeueMono", serif;
	--font-weight-light: 300;
	--font-weight-regular: 400;
	--font-weight-bold: 600;

	/* Spaces. */
	--space-small: 1.25rem;
	--space-medium: 3rem;
	--space-large: 4rem;
	--space-x-large: 5rem;
	--space-50: 3.125rem;
	--space-100: 6.25rem;

	/* Animation and transition variables. */
	--time-x-small: 64ms;
	--time-small: 96ms;
	--time-medium: 208ms;
	--time-large: 400ms;
	--time-xlarge: 800ms;
	--ease-in-quad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
	--ease-out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
	--ease-out-quart: cubic-bezier(0.165, 0.84, 0.44, 1);

	/* Scroll gallery. */
	--scroll-gallery-height: calc(100vh - 2.5rem);
	--scroll-gallery-height-small: calc(100vh - 5rem);
	--scroll-gallery-height-medium: calc(100vh - 7.75rem);
	--scroll-gallery-height-wide: calc(100vh - 10.5rem);
	/*  */
	--site-navigation-active-background-blur: blur(4px);

	/* Z-indices. */
	--z-index-folder: 9996;
	--z-index-charity-heart: 9997;
	--z-index-site-navigation: 9998;
	--z-index-convincers: 9999;
}
