.types {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}

.types__item {
	color: var(--color-orange);
	cursor: pointer;
}

.types__item__dot,
.types__item__check {
	display: inline-block;
	opacity: 0;
	transform: rotate(45deg) scale(0.8) translateZ(0);
	transform-origin: center center;
	transition:
		opacity var(--time-small) var(--ease-in-quad),
		transform var(--time-small) var(--ease-in-quad);
	width: 0;
}

.types__item__dot::after {
	content: "\00b7";
}

.types__item:hover .types__item__dot {
	opacity: 1;
	transform: translateZ(0);
}

.types__item__check::after {
	content: "\2714\FE0E";
}

.l-work__toggle:nth-of-type(1):checked ~ .l-work__wrapper .types__item:nth-child(1) .types__item__check,
.l-work__toggle:nth-of-type(2):checked ~ .l-work__wrapper .types__item:nth-child(2) .types__item__check,
.l-work__toggle:nth-of-type(3):checked ~ .l-work__wrapper .types__item:nth-child(3) .types__item__check,
.l-work__toggle:nth-of-type(4):checked ~ .l-work__wrapper .types__item:nth-child(4) .types__item__check,
.l-work__toggle:nth-of-type(5):checked ~ .l-work__wrapper .types__item:nth-child(5) .types__item__check,
.l-work__toggle:nth-of-type(6):checked ~ .l-work__wrapper .types__item:nth-child(6) .types__item__check,
.l-work__toggle:nth-of-type(7):checked ~ .l-work__wrapper .types__item:nth-child(7) .types__item__check,
.l-work__toggle:nth-of-type(8):checked ~ .l-work__wrapper .types__item:nth-child(8) .types__item__check {
	opacity: 1;
	transform: translateZ(0);
}
