@keyframes fade-text-in {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes fade-and-slide-text-in {
	0% {
		opacity: 0;
		transform: translate(-2rem, 0);
	}

	100% {
		opacity: 1;
		transform: translate(0, 0);
	}
}

.l-home {
	background-color: var(--color-orange);
	display: flex;
	overflow: hidden;
	position: relative;
	height: 200vh;
}

@media (--mq-up-to-small) {
	.l-home {
		align-content: center;
		align-items: flex-start;
	}
}

@media (--mq-from-small) {
	.l-home {
		align-items: center;
		height: 100vh;
	}
}

.l-home__wrapper {
	display: block;
	width: 100%;
}

.l-home__list,
.l-home__taglines {
	display: block;
	list-style: none;
	margin: 0;
	padding: 0;
}

.l-home__list {
	text-transform: uppercase;
	width: 100%;
}

@media (--mq-up-to-small) {
	.l-home__list {
		text-align: center;
	}
}

@media (--mq-from-small) {
	.l-home__list {
		display: flex;
		justify-content: space-between;
	}
}

@media (--mq-up-to-small) {
	.l-home__list__item {
		margin-bottom: calc(50vh - 3rem);
	}
}

@media (--mq-from-small) {
	.l-home__list__item:nth-child(3) {
		padding-right: 8vw;
	}
}

@media (--mq-from-small) {
	.l-home__list__item:nth-child(4) {
		padding-right: 3vw;
	}
}

.l-home__list__link {
	color: var(--color-white);
	font-size: 1.5rem;
	position: relative;
	text-decoration: none;
	z-index: 12;
}

.l-home__list__link:focus,
.l-home__list__link:hover,
.l-home__list__link.l-home__list__link--current {
	color: var(--color-black);
}

.l-home__list__link.l-home__list__link--current {
	font-weight: var(--font-weight-bold);
}

.l-home__list__link:not(.l-home__list__link--current) {
	animation: fade-and-slide-text-in var(--time-small) var(--ease-out-quad) var(--time-large) forwards;
	opacity: 0;
	transform: translate(-2rem, 0);
}

.l-home__list__item:nth-child(3) .l-home__list__link:not(.l-home__list__link--current) {
	animation-delay: calc(var(--time-large) + (var(--time-small) * 1));
}

.l-home__list__item:nth-child(4) .l-home__list__link:not(.l-home__list__link--current) {
	animation-delay: calc(var(--time-large) + (var(--time-small) * 2));
}

.l-home__taglines__item {
	position: absolute;
	min-width: 225px;
	max-width: 90vw;
}

.l-home__taglines__item.l-home__taglines__item--align-left-mobile {
	text-align: left;
	transform: translate(0, -50%);
}

.l-home__taglines__item.l-home__taglines__item--align-center-mobile {
	text-align: center;
	transform: translate(-50%, -50%);
}

.l-home__taglines__item.l-home__taglines__item--align-right-mobile {
	text-align: right;
	transform: translate(-100%, -50%);
}

@media (--mq-from-small) {
	.l-home__taglines__item {
		min-width: 250px;
		max-width: 500px;
	}

	.l-home__taglines__item.l-home__taglines__item--align-left-desktop {
		text-align: left;
		transform: translate(0, -50%);
	}

	.l-home__taglines__item.l-home__taglines__item--align-center-desktop {
		text-align: center;
		transform: translate(-50%, -50%);
	}

	.l-home__taglines__item.l-home__taglines__item--align-right-desktop {
		text-align: right;
		transform: translate(-100%, -50%);
	}
}

.l-home__taglines__link {
	animation: fade-text-in var(--time-small) var(--ease-out-quad) var(--time-large) forwards;
	font-family: var(--font-mono);
	font-size: 0.875rem;
	font-weight: var(--font-weight-regular);
	opacity: 0;
	text-decoration: none;
}

@media (--mq-from-small) {
	.l-home__taglines__link {
		font-size: 1rem;
	}
}

.l-home__taglines__item:nth-child(1) .l-home__taglines__link {
	animation-delay: calc(var(--time-large) * 2 + (var(--time-small) * 1));
}

.l-home__taglines__item:nth-child(2) .l-home__taglines__link {
	animation-delay: calc(var(--time-large) * 2 + (var(--time-small) * 2));
}

.l-home__taglines__item:nth-child(3) .l-home__taglines__link {
	animation-delay: calc(var(--time-large) * 2 + (var(--time-small) * 3));
}

.l-home__taglines__item:nth-child(4) .l-home__taglines__link {
	animation-delay: calc(var(--time-large) * 2 + (var(--time-small) * 4));
}

.l-home__taglines__item:nth-child(5) .l-home__taglines__link {
	animation-delay: calc(var(--time-large) * 2 + (var(--time-small) * 5));
}

.l-home__taglines__item:nth-child(6) .l-home__taglines__link {
	animation-delay: calc(var(--time-large) * 2 + (var(--time-small) * 6));
}

.l-home__taglines__item:nth-child(7) .l-home__taglines__link {
	animation-delay: calc(var(--time-large) * 2 + (var(--time-small) * 7));
}

.l-home__taglines__item:nth-child(8) .l-home__taglines__link {
	animation-delay: calc(var(--time-large) * 2 + (var(--time-small) * 8));
}

.l-home__taglines__item:nth-child(9) .l-home__taglines__link {
	animation-delay: calc(var(--time-large) * 2 + (var(--time-small) * 9));
}
