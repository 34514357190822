.section + .section {
	margin-top: 2rem;
}

@media (--mq-from-medium) {
	.section + .section {
		margin-top: 3rem;
	}
}

@media (--mq-from-wide) {
	.section + .section {
		margin-top: 4rem;
	}
}
