.projects {
	list-style: none;
	margin: 0;
	padding: 0;
}

.projects__item {
	color: var(--color-black);
	transition: color var(--time-small) var(--ease-in-quad);
}

.l-work__toggle:checked ~ .l-work__wrapper .projects__item {
	color: var(--color-grey);
}

.l-work__toggle:nth-of-type(1):checked ~ .l-work__wrapper .projects__item.projects__item--type-1,
.l-work__toggle:nth-of-type(2):checked ~ .l-work__wrapper .projects__item.projects__item--type-2,
.l-work__toggle:nth-of-type(3):checked ~ .l-work__wrapper .projects__item.projects__item--type-3,
.l-work__toggle:nth-of-type(4):checked ~ .l-work__wrapper .projects__item.projects__item--type-4,
.l-work__toggle:nth-of-type(5):checked ~ .l-work__wrapper .projects__item.projects__item--type-5,
.l-work__toggle:nth-of-type(6):checked ~ .l-work__wrapper .projects__item.projects__item--type-6,
.l-work__toggle:nth-of-type(7):checked ~ .l-work__wrapper .projects__item.projects__item--type-7,
.l-work__toggle:nth-of-type(8):checked ~ .l-work__wrapper .projects__item.projects__item--type-8 {
	color: var(--color-black);
}

.projects__item:focus,
.projects__item:hover {
	color: var(--color-black) !important;
}

.projects__link {
	border-bottom: 1px solid var(--color-grey);
	color: currentColor;
	display: flex;
	gap: 1.25rem;
	padding: 0.5rem 0;
	justify-content: flex-start;
	align-items: flex-start;
	text-decoration: none;
	transition: border-bottom-color var(--time-small) var(--ease-in-quad);
}

@media (--mq-from-small) {
	.projects__link {
		padding: 1rem 0;
	}
}

.projects__year {
	flex-basis: 64px;
	flex-grow: 0;
	flex-shrink: 0;
}

@media (--mq-from-small) {
	.projects__year {
		flex-basis: 18%;
	}
}

.projects__excerpt {
	flex-grow: 1;
	flex-shrink: 1;
	transition: color var(--time-small) var(--ease-in-quad);
}

@media (--mq-from-small) {
	.projects__excerpt {
		flex-basis: 45%;
		flex-grow: 0;
	}
}

.projects__link:focus .projects__excerpt,
.projects__link:hover .projects__excerpt {
	color: var(--color-orange);
}

@media (--mq-up-to-small) {
	.projects__types {
		display: none;
	}
}

/* mq-up-to-medium */
@media (--mq-up-to-medium) {
	.projects__ongoing {
		display: none;
	}
}

.projects__types {
	flex-grow: 0;
	flex-shrink: 0;
}

.projects__ongoing {
	color: var(--color-purple);
	flex-grow: 1;
	flex-shrink: 0;
	text-align: right;
}
