.magic-cursor-item {
	display: block;
	opacity: 1;
	pointer-events: none;
	position: fixed;
	top: 0;
	left: 0;
}

.magic-cursor-item.is-fading {
	animation-direction: normal;
	animation-duration: 800ms;
	animation-fill-mode: forwards;
	animation-iteration-count: 1;
	animation-name: fadeOut;
	animation-timing-function: linear;
}

@keyframes fadeOut {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
	}
}
