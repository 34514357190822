.media-block-wrapper {
	box-sizing: border-box;
}

.media-block-wrapper.media-block-wrapper--small,
.media-block-wrapper.media-block-wrapper--default {
	padding: 2rem;
}

@media (--mq-from-small) {
	.media-block-wrapper.media-block-wrapper--small,
	.media-block-wrapper.media-block-wrapper--default {
		padding: 3rem;
	}
}

@media (--mq-from-wide) {
	.media-block-wrapper.media-block-wrapper--default {
		padding: 5rem;
	}
}

@media (--mq-from-large) {
	.media-block-wrapper.media-block-wrapper--default {
		padding: 8rem;
	}
}

.section--no-padding-bottom .media-block-wrapper {
	padding-bottom: 0;
}
