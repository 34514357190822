.media {
	display: block;
	overflow: hidden;
	position: relative;
	text-align: center;
}

.media__wrapper {
	display: block;
	width: 100%;
}

.l-stream-item .media__wrapper:not(:only-child) {
	margin-bottom: 1.375rem;
}

[data-aspect-ratio] .media__wrapper {
	overflow: hidden;
	padding-top: 100%;
	position: relative;
}

[data-aspect-ratio="1:1"] .media__wrapper {
	padding-top: 100%;
}

[data-aspect-ratio="1:2"] .media__wrapper {
	padding-top: 200%;
}

[data-aspect-ratio="2:1"] .media__wrapper {
	padding-top: 50%;
}

[data-aspect-ratio="2:3"] .media__wrapper {
	padding-top: calc(100% * (3 / 2));
}

[data-aspect-ratio="3:2"] .media__wrapper {
	padding-top: calc(100% * (2 / 3));
}

[data-aspect-ratio="3:4"] .media__wrapper {
	padding-top: calc(100% * (4 / 3));
}

[data-aspect-ratio="4:3"] .media__wrapper {
	padding-top: calc(100% * (3 / 4));
}

[data-aspect-ratio="16:9"] .media__wrapper {
	padding-top: calc(100% * (9 / 16));
}

[data-aspect-ratio="21:9"] .media__wrapper {
	padding-top: calc(100% * (9 / 21));
}

@media (width < 48rem) {
	[data-mobile-aspect-ratio] .media__wrapper {
		overflow: hidden;
		padding-top: 100%;
		position: relative;
	}

	[data-mobile-aspect-ratio="1:1"] .media__wrapper {
		padding-top: 100%;
	}

	[data-mobile-aspect-ratio="1:2"] .media__wrapper {
		padding-top: 200%;
	}

	[data-mobile-aspect-ratio="2:1"] .media__wrapper {
		padding-top: 50%;
	}

	[data-mobile-aspect-ratio="2:3"] .media__wrapper {
		padding-top: calc(100% * (3 / 2));
	}

	[data-mobile-aspect-ratio="3:2"] .media__wrapper {
		padding-top: calc(100% * (2 / 3));
	}

	[data-mobile-aspect-ratio="3:4"] .media__wrapper {
		padding-top: calc(100% * (4 / 3));
	}

	[data-mobile-aspect-ratio="4:3"] .media__wrapper {
		padding-top: calc(100% * (3 / 4));
	}

	[data-mobile-aspect-ratio="16:9"] .media__wrapper {
		padding-top: calc(100% * (9 / 16));
	}

	[data-mobile-aspect-ratio="21:9"] .media__wrapper {
		padding-top: calc(100% * (9 / 21));
	}
}

.media__item {
	display: block;
	width: 100%;
	z-index: 1;
}

[data-aspect-ratio] .media__item {
	margin-top: 0;
	margin-bottom: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	height: auto;
	z-index: 0;
}

@supports (object-fit: cover) {
	[data-aspect-ratio] .media__item {
		object-fit: cover;
		max-width: none;
		height: 100%;
	}
}

@media (width < 48rem) {
	[data-mobile-aspect-ratio] .media__item {
		margin-top: 0;
		margin-bottom: 0;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		height: auto;
		z-index: 0;
	}

	@supports (object-fit: cover) {
		[data-mobile-aspect-ratio] .media__item {
			object-fit: cover;
			max-width: none;
			height: 100%;
		}
	}
}

.media__caption {
	font-family: var(--font-mono);
	position: absolute;
	z-index: 2;
}

/* text alignment */

.media__caption.media__caption--left-bottom,
.media__caption.media__caption--left-center,
.media__caption.media__caption--left-top {
	text-align: left;
}

.media__caption.media__caption--center-bottom,
.media__caption.media__caption--center-center,
.media__caption.media__caption--center-top {
	text-align: center;
}

.media__caption.media__caption--right-bottom,
.media__caption.media__caption--right-center,
.media__caption.media__caption--right-top {
	text-align: right;
}

/* vertical position */

.media__caption.media__caption--center-bottom,
.media__caption.media__caption--left-bottom,
.media__caption.media__caption--right-bottom {
	bottom: 1rem;
}

.media__caption.media__caption--left-center,
.media__caption.media__caption--right-center {
	top: 50%;
	transform: translateY(-50%);
}

.media__caption.media__caption--center-top,
.media__caption.media__caption--left-top,
.media__caption.media__caption--right-top {
	top: 1rem;
}

/* horizontal position */

.media__caption.media__caption--center-bottom,
.media__caption.media__caption--center-top {
	left: 50%;
	transform: translateX(-50%);
}

.media__caption.media__caption--left-bottom,
.media__caption.media__caption--left-center,
.media__caption.media__caption--left-top {
	left: 1rem;
}

.media__caption.media__caption--right-bottom,
.media__caption.media__caption--right-center,
.media__caption.media__caption--right-top {
	right: 1rem;
}

/* center center */

.media__caption.media__caption--center-center {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

@media (width >= 48rem) {
	.media__caption {
		font-size: 1rem;
	}
}

.l-stream-item .media__caption {
	color: var(--color-grey);
	position: relative;
	text-align: left;
	top: auto;
	right: auto;
	bottom: auto;
	left: auto;
	transform: none;
}

.compare-media-block {
	display: grid;
}

.compare-media-block__item {
	grid-area: 1 / 1;
}

.compare-media-block__item[data-compare-media="before"] {
	mask: linear-gradient(to right, #000 0, var(--compare-media-block--position, 50%), #0000 0);
}

.compare-media-block__item[data-compare-media="after"] {
	mask: linear-gradient(to right, #0000 0, var(--compare-media-block--position, 50%), #000 0);
}

.compare-media-block__slider {
	appearance: none;
	background: none;
	cursor: pointer;
	grid-area: 1 / 1;
	z-index: 1;
	-webkit-tap-highlight-color: transparent;
}

.compare-media-block__slider::-webkit-slider-runnable-track {
	height: 100%;
}

.compare-media-block__slider::-moz-range-track {
	height: 100%;
}

.compare-media-block__slider::-webkit-slider-thumb {
	appearance: none;
	background-color: var(--compare-media-block--thumb-color, #fff);
	border: none;
	border-radius: 0;
	box-sizing: border-box;
	width: 4px;
	height: 100%;
}

.compare-media-block__slider::-moz-range-thumb {
	appearance: none;
	background-color: var(--compare-media-block--thumb-color, #fff);
	border: none;
	border-radius: 0;
	box-sizing: border-box;
	width: 4px;
	height: 100%;
}
/* mq-from-medium */
@media (--mq-from-medium) {
	.compare-media-block__slider::-webkit-slider-thumb {
		width: 6px;
	}

	.compare-media-block__slider::-moz-range-thumb {
		width: 6px;
	}
}
