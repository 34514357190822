.button {
	background-color: #d8d8d8;
	border: solid 1px #b5b5b5;
	box-shadow: inset 1px 1px 0 0 #ffffff;
	box-sizing: border-box;
	color: #303030;
	cursor: pointer;
	display: inline-block;
	font-family: var(--sans-serif);
	font-size: 0.875rem;
	padding: 8px 45px;
	text-align: center;
	text-decoration: none;
	text-shadow: 0 1px 0 #ffffff;
	width: auto;
}

.button:active {
	box-shadow:
		inset 1px 1px 0 0 #cccccc,
		inset -1px -1px 0 0 #ffffff;
	text-shadow: unset;
}

button.button {
	appearance: none;
	line-height: 1.75rem;
	outline: 0;
}
