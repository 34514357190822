.l-stream-item {
	background-color: var(--color-grey-light);
	position: relative;
}

.l-stream-item {
	padding-top: 3rem;
}

@media (width >= 64rem) {
	.l-stream-item {
		padding-top: 5rem;
	}
}

@media (width >= 105rem) {
	.l-stream-item {
		padding-top: var(--space-100);
	}
}

.l-stream-item__title {
	color: var(--color-orange);
	font-family: var(--font-sans);
	font-size: 1rem;
	font-weight: var(--font-weight-regular);
	line-height: 1.7143;
	margin-bottom: 0 !important;
}

@media (width >= 48rem) {
	.l-stream-item__title {
		font-size: 1.125rem;
		line-height: 1.75;
	}
}

@media (width >= 86.25rem) {
	.l-stream-item__title {
		font-size: 1.375rem;
		line-height: 1.5;
	}
}

@media (width >= 105rem) {
	.l-stream-item__title {
		font-size: 1.5rem;
		line-height: 1.5455;
	}
}

@media (width >= 48rem) {
	.l-stream-item__content {
		width: 600px;
	}
}

@media (width >= 86.25rem) {
	.l-stream-item__content {
		width: 900px;
	}
}

/* Specific section spacing on the stream item layout. */
.l-stream-item__content > .section,
.l-stream-item__content > .section + .section {
	margin-top: var(--space-50);
	margin-bottom: var(--space-50);
}

@media (width >= 86.25rem) {
	.l-stream-item__content > .section,
	.l-stream-item__content > .section + .section {
		margin-top: var(--space-100);
		margin-bottom: var(--space-100);
	}
}

.l-stream-item__content > *.section:last-of-type:not(:last-child) {
	margin-bottom: var(--space-50);
}

/* Text wrappers have fixed widths on most screens. */
.l-stream-item__content > .section .text {
	width: 100%;
}

@media (width >= 48rem) and (width < 86.25rem) {
	.l-stream-item__content > .section .text {
		width: 500px;
	}
}

@media (width >= 86.25rem) and (width < 105rem) {
	.l-stream-item__content > .section .text {
		width: 700px;
	}
}

/* Titles follow body font styles on the stream item layout + they're bold. */
.l-stream-item__content:is(h2, h3, h4, h5) {
	font-family: var(--font-sans);
	font-size: 0.875rem;
	font-weight: var(--font-weight-bold);
	line-height: 1.7143;
}

@media (width >= 48rem) {
	.l-stream-item__content:is(h2, h3, h4, h5) {
		font-size: 1rem;
		line-height: 1.75;
	}
}

@media (width >= 86.25rem) {
	.l-stream-item__content:is(h2, h3, h4, h5) {
		font-size: 1.25rem;
		line-height: 1.5;
	}
}

@media (width >= 105rem) {
	.l-stream-item__content:is(h2, h3, h4, h5) {
		font-size: 1.375rem;
		line-height: 1.5455;
	}
}

.l-stream-item__content:is(h3, h4, h5) {
	margin-bottom: 0;
}

.l-stream-item__content h5 {
	opacity: 0.55;
}

/* Heading level 3 indenting. */
h3.l-stream-item__content,
h3.l-stream-item__content + p {
	text-indent: 1.875rem;
}

@media (width >= 86.25rem) {
	h3.l-stream-item__content,
	h3.l-stream-item__content + p {
		text-indent: 3.75rem;
	}
}

/* Heading level 4 + 5 indenting. */
h4.l-stream-item__content,
h4.l-stream-item__content + p,
h5.l-stream-item__content,
h5.l-stream-item__content + p {
	text-indent: 1.875rem;
}

@media (width >= 86.25rem) {
	h4.l-stream-item__content,
	h4.l-stream-item__content + p,
	h5.l-stream-item__content,
	h5.l-stream-item__content + p {
		text-indent: 5.625rem;
	}
}

/* Links have a different color on the stream item layout. */
.l-stream-item__content p > a {
	color: var(--color-purple);
}

.l-stream-item__article-footer {
	font-family: var(--font-mono);
	font-size: 0.6875rem;
	line-height: 1.6364;
}

@media (width >= 48rem) {
	.l-stream-item__article-footer {
		width: 500px;
	}
}

@media (width >= 86.25rem) {
	.l-stream-item__article-footer {
		font-size: 0.875rem;
		line-height: 1.5;
		width: 700px;
	}
}

.l-stream-item__archive-list {
	border-top: 1px solid var(--color-grey);
	margin-top: var(--space-100);
	margin-bottom: var(--space-100);
	padding-top: var(--space-100);
}

.l-stream-item__footer {
	border: 0;
	color: currentColor;
	display: block;
	padding-bottom: var(--space-50);
	text-decoration: none;
	transition: opacity 96ms linear;
}

@media (width >= 86.25rem) {
	.l-stream-item__footer {
		padding-bottom: var(--space-100);
	}
}

.l-stream-item__footer:hover {
	opacity: 0.5;
}
