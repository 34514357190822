.l-stream {
	background-color: var(--color-grey-light);
	box-sizing: border-box;
	padding-top: var(--space-50);
	min-height: 100vh;

	/* Uncomment when translation functionality is added. */

	/* &.l-stream--has-translator::before {
		background-color: red;
		content: '';
		display: block;
		height: 1px;
		left: 0;
		opacity: 0.5;
		position: fixed;
		top: 12.5rem;
		width: 100%;
		z-index: 3;

		@media (--mq-from-wide) {
			top: 10rem;
		}
	} */
}

@media (--mq-from-wide) {
	.l-stream {
		padding-top: var(--space-100);
	}
}

.l-stream.l-stream--has-translator {
	padding-top: 16.875rem;
	position: relative;
	min-height: 200vh;
}

@media (--mq-from-medium) {
	.l-stream.l-stream--has-translator {
		padding-top: 20rem;
	}
}

.l-stream__footer {
	padding-top: var(--space-50);
	padding-bottom: var(--space-50);
}

@media (--mq-from-wide) {
	.l-stream__footer {
		padding-top: var(--space-100);
		padding-bottom: var(--space-100);
	}
}
