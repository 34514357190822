.livestream {
}

.livestream__video-container {
	background-color: #000000;
	padding-top: 75%;
	position: relative;
	width: 100%;
}

.livestream__video-container video {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.livestream__stats {
	border-bottom: 1px solid var(--color-black);
	box-sizing: border-box;
	display: block;
	list-style: none;
	margin: 0;
	padding: 1.25rem;
	padding-top: 0.75rem;
}

.livestream__stats__item {
	display: inline-block;
	font-family: var(--font-mono);
	font-size: 0.75rem;
}

.livestream__stats__item:not(:last-child) {
	margin-right: 1rem;
}

.livestream__actions {
	padding: 1.25rem;
	text-align: center;
}

@media (--mq-from-medium) {
	.livestream__actions {
		padding: var(--space-medium);
	}
}

.livestream__actions .button {
	display: inline-block;
}

.livestream__actions .button + .button {
	margin-left: var(--space-medium);
}
