.hero {
	display: flex;
	align-items: center;

	/* this fixes IE11 flexbug on min-height */
	padding-right: 10%;
	padding-left: 10%;
	justify-content: center;
	position: relative;
	text-align: center;
	height: 100px;
	min-height: 100vh;
}

@media (--mq-from-medium) {
	.hero {
		padding-right: 25%;
		padding-left: 25%;
	}
}

.hero__background {
	overflow: hidden;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
}

.hero__background__media {
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: auto;
	min-width: 100%;
	max-width: none;
	height: auto;
	min-height: 100%;
}

@supports (object-fit: cover) {
	.hero__background__media {
		object-fit: cover;
		object-position: 50% 50%;
		top: auto;
		left: auto;
		transform: none;
		width: 100%;
		height: 100%;
	}
}

.hero__content {
	color: currentColor;
	position: relative;
	z-index: 2;
}

.hero__title {
	font-weight: var(--font-weight-light);
	margin-top: 0;
	margin-bottom: 2rem;
	text-transform: uppercase;
}

.hero__subtitle {
	font-weight: var(--font-weight-light);
}
