.l-artists {
}

@media (--mq-up-to-medium) {
	.l-artists__content {
		box-sizing: border-box;
		display: block;
		padding-right: 1.25rem;
		padding-left: 1.25rem;
		width: 100%;
	}
}

@media (--mq-from-medium) {
	.l-artists__content {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-end;
	}
}

.l-artists__content__article {
	font-size: 0.875rem;
	padding-bottom: 4.375rem;
	text-align: center;
	text-decoration: none;

	/*
	 * To edit the hoverstate, please view following inline style:
	 * wp-content/themes/mrhenry/views/artists.twig:15
	 *  */
}

@media (--mq-up-to-medium) {
	.l-artists__content__article {
		display: block;
		margin-left: 0 !important;
		width: 100% !important;
	}
}

@media (--mq-from-medium) {
	.l-artists__content__article {
		flex-grow: 0;
		flex-shrink: 0;
		font-size: 1.125rem;
		padding-bottom: 12.5rem;
	}
}

.l-artists__content__media {
	margin-bottom: 1.25rem;
	width: 100%;
}

@media (--mq-from-medium) {
	.l-artists__content__media {
		margin-bottom: 1.875rem;
	}
}
