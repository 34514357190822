.group-navigation {
	display: block;
	list-style: none;
	padding-left: 0;
}

.group-navigation__link {
	color: var(--color-orange);
	font-weight: var(--font-weight-light);
	position: relative;
	text-decoration: none;
}

.group-navigation__link:not([href]) {
	color: var(--color-grey-medium);
}

@media (prefers-contrast: more) {
	.group-navigation__link:not([href]) {
		opacity: 0.42;
	}
}
