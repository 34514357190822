.project-rows {
	box-sizing: border-box;
	overflow: hidden;
	width: 100%;
	max-width: 100%;
}

@media (width < 48rem) {
	.project-rows {
		margin-bottom: 1.25rem;
	}
}

@media (width >= 48rem) and (width < 64rem) {
	.project-rows {
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}
}

@media (width >= 64rem) {
	.project-rows {
		padding-right: var(--space-medium);
		padding-left: var(--space-medium);
	}
}

@media (width < 48rem) {
	.section--no-padding-bottom .project-rows {
		margin-bottom: 0;
	}
}

@media (width >= 48rem) {
	.project-rows__row {
		display: flex;
		align-items: center;
	}
}

.project-rows__column {
	align-items: center;
	text-decoration: none;
}

@media (width < 48rem) {
	.project-rows__column {
		margin-top: 1.25rem !important;
		margin-left: 0 !important;
	}
}

@media (width >= 48rem) {
	.project-rows__column {
		padding: 4vh 2vw;
	}
}

@media (width >= 48rem) {
	.project-rows__column:first-child {
		padding-left: 0;
	}
}

@media (width >= 48rem) {
	.project-rows__column:last-child {
		padding-right: 0;
	}
}

@media (width >= 48rem) {
	.section--no-padding-bottom .project-rows__row:last-child .project-rows__column {
		padding-bottom: 0;
	}
}

.project-rows__column__text {
	text-align: center;
}

@media (width < 48rem) {
	.project-rows__column__text {
		padding: 3.75rem 1.25rem;
		max-width: none !important;
	}
}

@media (width >= 48rem) {
	.project-rows__column__text {
		min-width: 25vw;
	}
}

.project-rows__column__text a {
	color: currentColor;
}

.project-rows__column__project {
	opacity: 1;
	transition: opacity var(--time-small) var(--ease-out-quad);
}

.project-rows__column__project:focus,
.project-rows__column__project:hover {
	opacity: 0.7;
}

@media (width >= 48rem) {
	.project-rows__column__project {
		display: flex;
		align-items: center;
	}
}

@media (width < 48rem) {
	mr-group.is-resolved .project-rows__column__project {
		display: block;
		opacity: 0.3;
		transition: opacity var(--time-small) var(--ease-in-quad);
	}
}

@media (width < 48rem) {
	mr-group.is-resolved .project-rows__column__project.is-active {
		opacity: 1;
	}
}

.project-rows__column__media {
	display: none;
}

@media (width < 48rem) {
	.project-rows__column__media.project-rows__column__media--is_featured {
		display: block;
	}
}

@media (width >= 48rem) {
	.project-rows__column__media {
		display: inline-block;
		overflow: hidden;
		height: 15vw;
	}

	.project-rows__column__media .project-rows__column__picture,
	.project-rows__column__media .project-rows__column__img,
	.project-rows__column__media .project-rows__column__video {
		display: block;
		width: auto;
		max-width: none;
		height: 100%;
	}
}

@media (width >= 64rem) {
	.project-rows__column__media {
		height: 12vw;
	}
}

@media (width >= 86.25rem) {
	.project-rows__column__media {
		height: 10vw;
	}
}

.project-rows__column__title {
	display: none;
}
