.l-site {
	position: relative;
}

.l-site__content {
	display: block;
}

.l-site__navigation {
	pointer-events: none;
	position: fixed;
	top: 0;
	right: 0;
	transition: width 0ms linear;
	transition-delay: var(--time-small);
	width: 72px;
	height: 100vh;
	z-index: var(--z-index-site-navigation);
}

/* #region Charity */
.l-site__charity {
	display: block;
	margin-right: 1rem;
	margin-left: auto;
	pointer-events: none;
	position: fixed; /* Fallback for browsers without sticky support. */
	position: sticky;
	right: 0; /* Needed for the fallback. */
	bottom: 0;
	width: 32px;
	height: auto;
	z-index: var(--z-index-charity-heart);
}

@media (--mq-from-medium) {
	.l-site__charity {
		margin-right: var(--space-medium);
	}
}

.l-site__charity__link {
	display: block;
	pointer-events: all;
	position: absolute;
	right: 0;
	bottom: 1rem;
	transition: color var(--time-small) var(--ease-in-quad) filter var(--time-small) var(--ease-in-quad);
	width: 32px;
	height: 32px;
}

@media (--mq-from-medium) {
	.l-site__charity__link {
		bottom: var(--space-medium);
	}
}

.is-showing-site-navigation__modal .l-site .l-site__charity__link {
	color: var(--color-white) !important;
	filter: blur(2px);
	transition-delay: var(--time-small);
}

.l-site__charity__link svg {
	display: block;
	margin: 8px;
	width: 16px;
	height: 16px;
}
/* #endregion Charity */

.l-site__convincers {
	position: relative;
	z-index: var(--z-index-convincers);
}
