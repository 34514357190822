.gallery {
	box-sizing: border-box;
	padding-right: 1.25rem;
	padding-left: 1.25rem;
	position: relative;
	width: 100%;
	height: auto;
	z-index: 1;
}

@media (width >= 48rem) {
	.gallery {
		padding-right: 25%;
	}
}

@media (width >= 64rem) {
	.gallery {
		padding-left: var(--space-medium);
	}
}

.l-stream-item .gallery {
	padding-right: 0;
	padding-left: 0;
}

.gallery.gallery--padding-small,
.gallery.gallery--padding-default {
	padding-top: 2rem;
	padding-bottom: 4rem;
	padding-left: 2rem;
}

@media (width < 48rem) {
	.gallery.gallery--padding-small,
	.gallery.gallery--padding-default {
		padding-right: 2rem;
	}
}

@media (width >= 48rem) {
	.gallery.gallery--padding-small,
	.gallery.gallery--padding-default {
		padding-top: 3rem;
		padding-bottom: 3rem;
		padding-left: 3rem;
	}
}

@media (width >= 86.25rem) {
	.gallery.gallery--padding-default {
		padding-top: 5rem;
		padding-bottom: 5rem;
		padding-left: 5rem;
	}
}

@media (width >= 105rem) {
	.gallery.gallery--padding-default {
		padding-top: 8rem;
		padding-bottom: 8rem;
		padding-left: 8rem;
	}
}

.section--no-padding-bottom .gallery {
	padding-bottom: 0;
}

.gallery__track {
	display: grid;
	margin: 0;
	padding: 0;
	height: max-content;
}

.gallery__item {
	grid-row: 1;
	grid-column: 1;
	opacity: 0;
	transition: opacity 160ms linear;
	visibility: hidden;
	width: 100%;
	height: 100%;
	z-index: 0;
}

.gallery__item[data-slide="current"] {
	opacity: 1;
	visibility: visible;
	z-index: 1;
}

.gallery__item[data-slide="next"],
.gallery__item[data-slide="previous"] {
	pointer-events: none;
	z-index: 2;
}

.gallery__item figure {
	margin: 0;
}

.gallery__item__media-container {
	background-color: var(--color-grey);
	overflow: hidden;
	padding-top: calc(100% * (9 / 16));
	position: relative;
}

.gallery__item__media-container[data-aspect-ratio="1:1"] {
	padding-top: 100%;
}

.gallery__item__media-container[data-aspect-ratio="1:2"] {
	padding-top: 200%;
}

.gallery__item__media-container[data-aspect-ratio="2:1"] {
	padding-top: 50%;
}

.gallery__item__media-container[data-aspect-ratio="2:3"] {
	padding-top: calc(100% * (3 / 2));
}

.gallery__item__media-container[data-aspect-ratio="3:2"] {
	padding-top: calc(100% * (2 / 3));
}

.gallery__item__media-container[data-aspect-ratio="3:4"] {
	padding-top: calc(100% * (4 / 3));
}

.gallery__item__media-container[data-aspect-ratio="4:3"] {
	padding-top: calc(100% * (3 / 4));
}

.gallery__item__media-container[data-aspect-ratio="16:9"] {
	padding-top: calc(100% * (9 / 16));
}

.gallery__item__media-container[data-aspect-ratio="21:9"] {
	padding-top: calc(100% * (9 / 21));
}

.gallery__item__media-container:hover {
	cursor: pointer;
}

.gallery__item__media {
	display: block;
	margin: 0 auto;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: auto;
}

@supports (object-fit: cover) {
	.gallery__item__media {
		object-fit: cover;
		object-position: center;
		height: 100%;
	}
}

.gallery__item__caption,
.gallery__navigation {
	font-family: var(--font-mono);
	font-size: 0.875rem;
	z-index: 21;
}

@media (width >= 80rem) {
	.gallery__item__caption,
	.gallery__navigation {
		font-size: 1rem;
	}
}

.gallery__item__caption {
	color: var(--color-grey-medium);
	display: inline-block;
	margin-top: 1.5rem;
	width: 100%;
	min-height: 24px;
}

@media (width < 48rem) {
	.gallery__item__caption {
		padding-bottom: 2.5rem;
		text-align: center;
	}
}

@media (width >= 48rem) {
	.gallery__item__caption {
		margin-right: 1rem;
		min-height: 24px;
	}
}

@media (width >= 80rem) {
	.gallery__item__caption {
		min-height: 28px;
	}
}

.l-stream-item .gallery__item__caption {
	margin-right: 0;
}

.gallery__inner {
	cursor: pointer;
}

.gallery__navigation {
	position: absolute;
	bottom: 0;
}

@media (width < 48rem) {
	.gallery__navigation {
		text-align: center;
		left: 0;
		width: 100%;
	}
}

@media (width >= 48rem) {
	.gallery__navigation {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
		top: 50%;
		right: 2rem;
		transform: translate(-5px, calc(-50% - 1.625rem));
		z-index: 2;
	}
}

@media (width >= 64rem) {
	.gallery__navigation {
		right: var(--space-medium);
	}
}

@media (width < 48rem) {
	.l-stream-item .gallery__navigation {
		width: 100%;
	}
}

@media (width >= 48rem) {
	.l-stream-item .gallery__navigation {
		right: -20%;
	}
}

@media (width >= 105rem) {
	.l-stream-item .gallery__navigation {
		right: -13.625rem;
	}
}

.gallery--padding-small .gallery__navigation,
.gallery--padding-default .gallery__navigation {
	bottom: 1rem;
}

.gallery__navigation__item {
	appearance: none;
	background: 0;
	border: 0;
	box-shadow: none;
	color: var(--color-grey-medium);
	outline: 0;
	padding: 0;
	transition: opacity 112ms 32ms linear;
}

@media (width >= 48rem) {
	.gallery__navigation__item {
		padding-top: 0.25rem;
		padding-bottom: 0.25rem;
	}
}

@media (width >= 80rem) {
	.gallery__navigation__item {
		padding-top: 0.375rem;
		padding-bottom: 0.375rem;
	}
}

.gallery__navigation__item:focus,
.gallery__navigation__item:hover,
[data-matches] .gallery__navigation__item {
	color: var(--color-black);
	text-decoration: underline;
	text-underline-offset: 2px;
}

.gallery__navigation__item:hover {
	cursor: pointer;
}
