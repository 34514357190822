.text--align-left {
	text-align: left;
}

.text--align-center {
	text-align: center;
}

.text--align-right {
	text-align: right;
}
