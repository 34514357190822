.nightfall-3d-model {
	border: none;
	display: none;
	margin: 0 auto;
	width: 100%;
	max-width: 23.4375rem;
	height: 32.9375rem;
}

/* mq-from-small */
@media (--mq-from-small) {
	.nightfall-3d-model {
		max-width: 26.25rem;
		height: 36.875rem;
	}
}

/* mq-from-medium */
@media (--mq-from-medium) {
	.nightfall-3d-model {
		max-width: 31.25rem;
		height: 43.9375rem;
	}
}

@media (width >= 90rem) {
	.nightfall-3d-model {
		max-width: 43.125rem;
		height: 60.625rem;
	}
}

.nightfall-3d-model[data-is-current] {
	display: block;
}

.nightfall-3d-model__select-product {
	border: none;
	color: var(--color-white);
	display: flex;
	flex-direction: column;
	font-family: var(--font-mono);
	font-size: 12px;
	margin-block: var(--space-medium);
	padding-inline: var(--space-small);
	align-items: center;
	text-transform: uppercase;
}

.nightfall-3d-model__select-product__label {
	margin-block-end: var(--space-small);
}

.nightfall-3d-model__select-product__items {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.nightfall-3d-model__select-product__item {
	display: flex;
	align-items: center;
}

.nightfall-3d-model__select-product__item:not(:last-child) {
	margin-inline-end: var(--space-small);
}

@media (width < 23.4375rem) {
	.nightfall-3d-model__select-product__item {
		margin-block-start: calc(var(--space-small) / 2);
	}
}

.nightfall-3d-model__select-product__item__input {
	margin-inline-end: 8px;
}
