.backflash-frame-tool {
	padding-top: var(--space-large);
	padding-right: var(--space-small);
	padding-bottom: var(--space-large);
	padding-left: var(--space-small);
}

@media (--mq-from-medium) {
	.backflash-frame-tool {
		margin-right: auto;
		margin-left: auto;
		max-width: 80%;
	}
}

@media (--mq-from-large) {
	.backflash-frame-tool {
		max-width: 80%;
	}
}

/* Frame with Media. */
.backflash-frame-tool__frame-with-media {
	margin-right: auto;
	margin-left: auto;
	align-self: flex-start;
	position: relative;
	max-width: 100%;
}

.backflash-frame-tool__media {
	position: absolute;
	top: 50%;
	left: 50%;
	height: auto;
}

@media (--mq-from-medium) {
	.backflash-frame-tool__frame-with-media {
		max-width: 80%;
	}
}

/* Controls. */
.backflash-frame-tool__controls {
	display: flex;
	padding-top: 3rem;
	padding-bottom: 2rem;
	justify-content: space-between;
	max-width: 100%;
}

.backflash-frame-tool__controls__1,
.backflash-frame-tool__controls__2 {
	width: 50%;
}

.backflash-frame-tool__controls h3 {
	font-family: var(--font-mono);
	font-size: 0.75rem;
	margin-bottom: 1rem;
	text-transform: uppercase;
}

@media (--mq-from-medium) {
	.backflash-frame-tool__controls {
		margin-right: auto;
		margin-left: auto;
		max-width: 80%;
	}
}

/* Form styles. */
@media (--mq-from-small) {
	.backflash-frame-tool__controls-fields {
		display: flex;
		line-height: 25px;
		align-items: center;
	}
}

.backflash-frame-tool__controls-fields label,
.backflash-frame-tool__controls-fields input {
	font-family: var(--font-mono);
	font-size: 0.75rem;
	text-transform: uppercase;
}

.backflash-frame-tool__controls-fields select,
.backflash-frame-tool__controls-fields button {
	font-size: 0.875rem;
}

.backflash-frame-tool__controls-fields label {
	display: inline-block;
	min-width: 80px;
}

/* Upload. */
.backflash-frame-tool__upload-button {
	font-size: 0.875rem;
	margin-top: 1rem;
	margin-bottom: 1rem;
	min-width: 180px;
	min-height: 45px;
}

.backflash-frame-tool__upload {
	display: flex;
	justify-content: center;
}

.backflash-frame-tool__random-button {
	display: flex;
	justify-content: center;
	min-width: 180px;
	min-height: 45px;
}

.backflash-frame-tool__random-button button {
	min-width: 180px;
	min-height: 45px;
}
