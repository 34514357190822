.l-folders {
	width: 100%;
	min-height: calc(100vh - 10rem);
}

@media (--mq-up-to-small) {
	.l-folders {
		border-top: 1px solid var(--color-grey);
		overflow-x: auto;
		overflow-y: visible;
		position: relative;
	}
}

@media (--mq-from-small) {
	.l-folders {
		overflow: hidden;
		min-height: calc(100vh - 16rem);
	}
}

@media (--mq-from-medium) {
	.l-folders {
		min-height: calc(100vh - 14rem);
	}
}

@media (--mq-from-wide) {
	.l-folders {
		min-height: calc(100vh - 12.5rem);
	}
}

.l-folders__wrapper {
	width: 100%;
}

.l-folders__desktop {
	background-color: #ffffff;
	box-sizing: border-box;
}

@media (--mq-from-small) {
	.l-folders__desktop {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		padding-right: var(--space-medium);
		padding-left: var(--space-medium);
		justify-items: center;
	}
}

@media (width >= 50.75rem) {
	.l-folders__desktop {
		grid-template-columns: repeat(4, 1fr);
	}
}

@media (width >= 61.9375rem) {
	.l-folders__desktop {
		grid-template-columns: repeat(5, 1fr);
	}
}

@media (width >= 73.125rem) {
	.l-folders__desktop {
		grid-template-columns: repeat(6, 1fr);
	}
}

@media (width >= 84.3125rem) {
	.l-folders__desktop {
		grid-template-columns: repeat(7, 1fr);
	}
}

@media (width >= 113.5rem) {
	.l-folders__desktop {
		padding-right: calc((100% - 107.5rem) / 2);
		padding-left: calc((100% - 107.5rem) / 2);
	}
}
