.wrapper {
	box-sizing: border-box;
	margin-right: auto;
	margin-left: auto;
	padding-right: var(--space-small);
	padding-left: var(--space-small);
	width: 100%;
}

@media (--mq-from-medium) {
	.wrapper {
		padding-right: var(--space-medium);
		padding-left: var(--space-medium);
	}
}

@media (--mq-from-small) {
	.wrapper.wrapper--small {
		padding-right: 12.5%;
		padding-left: 12.5%;
	}
}

@media (--mq-from-medium) {
	.wrapper.wrapper--small {
		padding-right: 15%;
		padding-left: 15%;
	}
}

@media (--mq-from-wide) {
	.wrapper.wrapper--small {
		padding-right: 20%;
		padding-left: 20%;
	}
}

@media (min-width: 120em) {
	.wrapper.wrapper--small {
		padding-right: calc((100% - 96rem) / 2);
		padding-left: calc((100% - 96rem) / 2);
	}
}

@media (min-width: 120em) {
	.wrapper.wrapper--large {
		padding-right: calc((100% - 107.5rem) / 2);
		padding-left: calc((100% - 107.5rem) / 2);
	}
}

.wrapper.wrapper--full-width {
	padding-right: 0;
	padding-left: 0;
}

@media (--mq-from-small) {
	.wrapper.wrapper--stream {
		padding-right: var(--space-50);
		padding-left: var(--space-50);
	}
}

@media (--mq-from-wide) {
	.wrapper.wrapper--stream {
		padding-right: var(--space-100);
		padding-left: var(--space-100);
	}
}
