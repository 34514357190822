@font-face {
	font-family: "MaisonNeue";
	src: url("../fonts/MaisonNeue-Light.woff2") format("woff2");
	font-style: normal;
	font-weight: 300;
}

@font-face {
	font-family: "MaisonNeue";
	src: url("../fonts/MaisonNeue-Book.woff2") format("woff2");
	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: "MaisonNeue";
	src: url("../fonts/MaisonNeue-Bold.woff2") format("woff2");
	font-style: normal;
	font-weight: 600;
}

@font-face {
	font-family: "MaisonNeueMono";
	src: url("../fonts/MaisonNeueMono-Regular.woff2") format("woff2");
	font-style: normal;
	font-weight: 400;
}
