.l-error {
	background-color: var(--color-purple);
	color: var(--color-pink);
	overflow: hidden;
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
}

.l-error.l-error--behind-debug-info {
	z-index: -1;
}

.l-error__intro {
	position: relative;
	z-index: 2;
}

.l-error__intro p > a {
	color: currentColor;
}

.l-error__background {
	overflow: hidden;
	pointer-events: none;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 280px;
	max-width: calc(100% - 2.5rem);
	height: 128px;
	z-index: 1;
}

/* Hide on weirdly small screens */
@media (max-height: 319px) {
	.l-error__background {
		display: none;
	}
}

@media (min-height: 300px) {
	.l-error__background {
		height: calc(100vh - 12.5rem);
		max-height: 524px;
	}
}

@media (--mq-from-xsmall) {
	.l-error__background {
		width: 500px;
	}
}

@media (--mq-from-small) {
	.l-error__background {
		width: 700px;
	}
}

@media (--mq-from-wide) {
	.l-error__background {
		width: 940px;
	}
}

@media (--mq-from-large) {
	.l-error__background {
		width: 1310px;
	}
}

.l-error__background svg {
	display: block;
	width: 100%;
	height: 100%;
}
