/* stylelint-disable @mrhenry/stylelint-mrhenry-attribute-selector-no-unknown */
.scroll-gallery {
	padding: 2rem;
	position: relative;
}

@media (--mq-from-small) {
	.scroll-gallery {
		padding: 3rem;
	}
}

@media (--mq-from-wide) {
	.scroll-gallery {
		padding: 5rem;
	}
}

@media (--mq-from-large) {
	.scroll-gallery {
		padding: 8rem;
	}
}

@media (min-width: 120em) {
	.scroll-gallery {
		padding-right: calc((100% - 104rem) / 2);
		padding-left: calc((100% - 104rem) / 2);
	}
}

.section--no-padding-bottom .scroll-gallery {
	padding-bottom: 0;
}

.scroll-gallery__gallery {
	--i: 1;
}

.scroll-gallery[gallery-size="2"] .scroll-gallery__gallery {
	--i: 2;
}

.scroll-gallery[gallery-size="3"] .scroll-gallery__gallery {
	--i: 3;
}

.scroll-gallery[gallery-size="4"] .scroll-gallery__gallery {
	--i: 4;
}

.scroll-gallery[gallery-size="5"] .scroll-gallery__gallery {
	--i: 5;
}

.scroll-gallery[gallery-size="6"] .scroll-gallery__gallery {
	--i: 6;
}

.scroll-gallery[gallery-size="7"] .scroll-gallery__gallery {
	--i: 7;
}

.scroll-gallery[gallery-size="8"] .scroll-gallery__gallery {
	--i: 8;
}

.scroll-gallery[gallery-size="9"] .scroll-gallery__gallery {
	--i: 9;
}

.scroll-gallery[gallery-size="10"] .scroll-gallery__gallery {
	--i: 10;
}

.scroll-gallery[gallery-size="11"] .scroll-gallery__gallery {
	--i: 11;
}

.scroll-gallery[gallery-size="12"] .scroll-gallery__gallery {
	--i: 12;
}

.scroll-gallery[gallery-size="13"] .scroll-gallery__gallery {
	--i: 13;
}

.scroll-gallery[gallery-size="14"] .scroll-gallery__gallery {
	--i: 14;
}

.scroll-gallery[gallery-size="15"] .scroll-gallery__gallery {
	--i: 15;
}

.scroll-gallery[gallery-size="16"] .scroll-gallery__gallery {
	--i: 16;
}

.scroll-gallery[gallery-size="17"] .scroll-gallery__gallery {
	--i: 17;
}

.scroll-gallery[gallery-size="18"] .scroll-gallery__gallery {
	--i: 18;
}

.scroll-gallery[gallery-size="19"] .scroll-gallery__gallery {
	--i: 19;
}

.scroll-gallery[gallery-size="20"] .scroll-gallery__gallery {
	--i: 20;
}

.scroll-gallery__gallery {
	position: relative;
	height: calc(var(--scroll-gallery-height) * var(--i));
	z-index: 1;
}

@media (--mq-from-small) {
	.scroll-gallery__gallery {
		height: calc(var(--scroll-gallery-height-small) * var(--i));
	}
}

@media (--mq-from-medium) {
	.scroll-gallery__gallery {
		height: calc(var(--scroll-gallery-height-medium) * var(--i));
	}
}

@media (--mq-from-wide) {
	.scroll-gallery__gallery {
		height: calc(var(--scroll-gallery-height-wide) * var(--i));
	}
}

.scroll-gallery__item {
	overflow: hidden;
	position: sticky;
	top: 1.25rem;
	width: 100%;
	height: var(--scroll-gallery-height);
}

@media (--mq-from-small) {
	.scroll-gallery__item {
		top: 2.5rem;
		height: var(--scroll-gallery-height-small);
	}
}

@media (--mq-from-medium) {
	.scroll-gallery__item {
		top: 3.875rem;
		height: var(--scroll-gallery-height-medium);
	}
}

@media (--mq-from-wide) {
	.scroll-gallery__item {
		top: 5.25rem;
		height: var(--scroll-gallery-height-wide);
	}
}

.scroll-gallery__item__media {
	display: block;
	margin-left: 50%;
	transform: translateX(-50%);
	width: auto;
	max-width: none;
	height: 100%;
}

@supports (object-fit: contain) {
	.scroll-gallery__item__media {
		margin-left: 0;
		object-fit: contain;
		object-position: center center;
		transform: none;
		width: 100%;
		height: 100%;
	}
}
