.site-navigation .site-navigation__wrapper {
	/* Wrapped inside l-site__navigation layout-component. */
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
}

/* #region Navigation Modal */
.site-navigation__modal {
	box-sizing: border-box;
	content-visibility: hidden;
	display: none;
	opacity: 0;
	outline: none;
	overflow-y: auto;
	pointer-events: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: var(--z-index-site-navigation);
}

.l-styleguide ~ .site-navigation__modal {
	z-index: var(--z-index-site-navigation);
}

.site-navigation__modal[data-state="opening"],
.site-navigation__modal[data-state="open"],
.site-navigation__modal[data-state="closing"] {
	content-visibility: visible;
	display: block;
	opacity: 1;
	pointer-events: auto;
}
/* #endregion Modal */

/* #region Navigation Trigger + Toggle */
.site-navigation__modal__close-trigger {
	height: 100%;
}

.site-navigation__modal__toggle {
	background: transparent;
	border: none;
	display: block;
	pointer-events: all;
	position: absolute;
	top: 1rem;
	right: 1rem;
	transform: translateZ(0);
	transition:
		color var(--time-small) var(--ease-in-quad),
		transform var(--time-small) var(--ease-in-quad);
	width: 32px;
	height: 32px;
	z-index: 10;
}

.site-navigation__modal__toggle:hover {
	cursor: pointer;
}

.site-navigation__modal__toggle::before,
.site-navigation__modal__toggle::after {
	background-color: currentColor;
	content: "";
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	width: 50%;
	height: 6.25%;
}

.site-navigation__modal__toggle::before {
	transform: translate(-50%, -50%);
}

.site-navigation__modal__toggle::after {
	transform: translate(-50%, -50%) rotate(90deg);
}

@media (--mq-from-medium) {
	.site-navigation__modal__toggle {
		top: var(--space-medium);
		right: var(--space-medium);
	}
}

.site-navigation__background-toggle {
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
}

.site-navigation__modal[data-state="open"] .site-navigation__background-toggle {
	display: block;
}
/* #endregion Navigation Toggle */

/* #region Navigation Container */
.site-navigation__modal__inner {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	opacity: 0;
	overflow: hidden;
	padding: 1.875rem;
	align-items: flex-start;
	pointer-events: none;
	position: absolute;
	top: 0;
	right: 0;
	transition: opacity var(--time-small) var(--ease-in-quad);
	width: 100%;
	height: auto;
	z-index: 2;
}

@media (--mq-from-small) {
	.site-navigation__modal__inner {
		max-width: 350px;
	}
}

@media (--mq-from-medium) {
	.site-navigation__modal__inner {
		padding: 3.125rem;
	}
}

.site-navigation__modal[data-state="open"] .site-navigation__modal__inner {
	opacity: 1;
	pointer-events: all;
	transition-delay: 0ms;
	width: 100%;
}
/* #endregion Navigation Container */

/* #region Navigation Link */
.site-navigation__link {
	box-sizing: border-box;
	color: var(--color-white);
	display: inline-block;
	font-size: 1.5rem;
	line-height: 1.33;
	padding: 1rem 0;
	position: relative;
	text-decoration: none;
	text-transform: uppercase;
	z-index: 2;
}

.site-navigation__link:focus,
.site-navigation__link:hover,
.site-navigation__link--current {
	color: var(--color-black);
}

.site-navigation__link:focus {
	outline: 0;
	text-decoration: underline;
}

.site-navigation__link--current {
	font-weight: var(--font-weight-bold);
}

.site-navigation__link:first-child {
	padding-top: 0;
}
/* #endregion Navigation Link */

/* #region Hlippy */
.site-navigation__hlippy {
	position: absolute;
	right: calc(100vw - 320px);
	bottom: 0;
	width: 200px;
	height: 200px;
	z-index: 1;
}

@media (--mq-from-small) {
	.site-navigation__hlippy {
		right: 0;
	}
}

.site-navigation__hlippy__frame {
	background-position: center center;
	background-repeat: no-repeat;
	display: block;
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.site-navigation__link:nth-child(1):hover ~ .site-navigation__hlippy .site-navigation__hlippy__frame--hover-1,
.site-navigation__link:nth-child(2):hover ~ .site-navigation__hlippy .site-navigation__hlippy__frame--hover-2,
.site-navigation__link:nth-child(3):hover ~ .site-navigation__hlippy .site-navigation__hlippy__frame--hover-3,
.site-navigation__link:nth-child(4):hover ~ .site-navigation__hlippy .site-navigation__hlippy__frame--hover-4 {
	opacity: 1;
}

.site-navigation__hlippy__frame--foot-down,
.site-navigation__hlippy__frame--foot-up {
	opacity: 1;
}

.site-navigation__link:hover ~ .site-navigation__hlippy .site-navigation__hlippy__frame--foot-down,
.site-navigation__link:hover ~ .site-navigation__hlippy .site-navigation__hlippy__frame--foot-up {
	animation: none;
	opacity: 0;
}

.site-navigation__hlippy__frame--foot-down {
	animation: MOVE_YOUR_FOOT var(--time-large) linear infinite;
	background-image: url("../images/navigation/hlippy-foot-down.svg");
}

.site-navigation__hlippy__frame--foot-up {
	animation: MOVE_YOUR_FOOT var(--time-large) linear reverse infinite;
	background-image: url("../images/navigation/hlippy-foot-up.svg");
}

@keyframes MOVE_YOUR_FOOT {
	0% {
		opacity: 1;
	}

	50% {
		opacity: 1;
	}

	60% {
		opacity: 0;
	}

	100% {
		opacity: 0;
	}
}

.site-navigation__modal[data-state="open"] .site-navigation__hlippy__frame--hover-1 {
	background-image: url("../images/navigation/hlippy-hover-1.svg");
}

.site-navigation__modal[data-state="open"] .site-navigation__hlippy__frame--hover-2 {
	background-image: url("../images/navigation/hlippy-hover-2.svg");
}

.site-navigation__modal[data-state="open"] .site-navigation__hlippy__frame--hover-3 {
	background-image: url("../images/navigation/hlippy-hover-3.svg");
}

.site-navigation__modal[data-state="open"] .site-navigation__hlippy__frame--hover-4 {
	background-image: url("../images/navigation/hlippy-hover-4.svg");
}
/* #endregion Hlippy */
