.window {
	background-color: var(--color-white);
}

@media (width < 48rem) {
	.window {
		display: block;
		overflow-x: hidden;
		overflow-y: auto;
		width: 200px;
		height: 100%;
	}
}

@media (width >= 48rem) {
	.window {
		box-shadow: 3px 3px 0 0 rgb(0 0 0 / 0.1);
		width: 600px;
	}
}

@media (width < 48rem) {
	.window.window--text,
	.window.window--image,
	.window.window--video {
		border-left: 1px solid var(--color-grey);
		box-sizing: border-box;
		padding: 1.25rem 1.25rem 4.5rem;
		width: calc(100vw - 60px);
	}
}

@media (width < 25rem) {
	.window.window--text,
	.window.window--image,
	.window.window--video {
		width: calc(100vw - 20px);
	}
}

.window__window {
	display: flex;
	position: relative;
	height: 100%;
}

.window__header {
	background-image: linear-gradient(to right, #3023ae, #53a0fd 48%, #b4ec51);
	color: #ffffff;
	/* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
	font-family: MaisonNeue;
	font-size: 1.125rem;
	font-stretch: normal;
	font-style: normal;
	font-weight: 400;
	letter-spacing: normal;
	line-height: 38px;
	position: relative;
	height: 38px;
}

@media (width < 48rem) {
	.window__header {
		display: none;
	}
}

.window__header.window__header--grey {
	background-color: var(--color-grey);
	background-image: none;
	color: rgb(0 0 0 / 0.3);
	font-family: var(--font-mono);
	font-size: 0.75rem;
	line-height: 30px;
	height: 30px;
}

.window__header__close-icon {
	background-color: var(--color-orange);
	border-radius: 50%;
	position: absolute;
	top: 50%;
	right: 16px;
	transform: translateY(-50%);
	width: 12px;
	height: 12px;
	z-index: 1;
}

.window__header__close-icon::after {
	background-color: var(--color-black);
	border-radius: 50%;
	content: "";
	opacity: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	transition: opacity 96ms linear;
	width: 6px;
	height: 6px;
	z-index: 2;
}

.window__header__close-icon:hover {
	cursor: pointer;
}

.window__header__close-icon:hover::after {
	opacity: 1;
}

.window__header__label {
	text-align: center;
}

.window__header__icon {
	box-sizing: border-box;
	display: inline-block;
	margin-bottom: 3px;
	padding-top: 3px;
	position: relative;
	vertical-align: middle;
	width: 30px;
	height: 21px;
}

.window__header__icon__image {
	background-image: linear-gradient(310deg, #ffd1a4, #ffff99);
	border: solid 1px #d9d96b;
	border-radius: 1px;
	box-shadow: 1px 1px 0 0 rgb(0 0 0 / 0.1);
	transform: scale3d(0.27, 0.27, 1);
	transform-origin: left top;
	width: 92px;
	height: 63px;
}

.window__header__icon__image::after {
	border-right: 9px solid transparent;
	border-bottom: 9px solid #ffff99;
	border-left: 9px solid transparent;
	content: "";
	display: block;
	position: absolute;
	top: -10px;
	left: 1px;
	width: 38px;
}

.window__header__icon__image::before {
	border-right: 10px solid transparent;
	border-bottom: 10px solid #d9d96b;
	border-left: 10px solid transparent;
	content: "";
	display: block;
	position: absolute;
	top: -11px;
	left: 0;
	width: 38px;
}

.window__footer {
	background-color: var(--color-grey);
	box-sizing: border-box;
	overflow: hidden;
	padding-right: 11px;
	padding-left: 11px;
	width: 100%;
	height: 29px;
}

@media (width < 48rem) {
	.window__footer {
		display: none;
	}
}

.window__footer__label {
	color: rgb(0 0 0 / 0.3);
	display: block;
	font-size: 0.8125rem;
	line-height: 29px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 578px;
}

.window__content {
	box-sizing: border-box;
	overflow-x: hidden;
	overflow-y: auto;
	height: 400px;
}

@media (width < 48rem) {
	.window__content {
		box-sizing: border-box;
		height: 100%;
	}
}

.window--image .window__content,
.window--video .window__content {
	position: relative;
	height: auto;
	max-height: 800px;
}

@media (width < 48rem) {
	.window--image .window__content,
	.window--video .window__content {
		border: 1px solid var(--color-grey);
		width: 100%;
		min-height: 0;
		max-height: none;
	}
}

.window--text .window__content {
	border-right: 1px solid var(--color-grey);
	border-bottom: 1px solid var(--color-grey);
	border-left: 1px solid var(--color-grey);
	padding: 2rem;
	height: auto;
}

@media (width < 48rem) {
	.window--text .window__content {
		border: 1px solid var(--color-grey);
		padding: 1.25rem;
		width: 100%;
		min-height: 0;
		max-height: none;
	}
}

.window--text .window__content p {
	font-family: var(--font-mono);
	font-size: 0.875rem;
}

.window--folder .window__content {
	border-left: 1px solid var(--color-grey);
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: flex-start;
	position: relative;
}

@media (width < 48rem) {
	.window--folder .window__content {
		flex-direction: column;
	}
}

@media (width >= 48rem) {
	.window--folder .window__content {
		border-right: 1px solid var(--color-grey);
		border-bottom: 1px solid var(--color-grey);
		position: relative;
	}
}

.window--folder .window__content::after,
.window--folder .window__content::before {
	content: "";
	position: absolute;
	top: calc(50vh - 10rem);
	left: 1.5rem;
	transform: translateY(-50%);
	width: calc(100% - 3rem);
	height: 1.5rem;
}

@media (width >= 48rem) {
	.window--folder .window__content::after,
	.window--folder .window__content::before {
		top: 50%;
	}
}

.window--folder .window__content::before {
	border: 2px solid var(--color-grey);
}

.window--folder .window__content::after {
	animation: RANDOM_LOADING_ANIMATION 5s steps(1, end) 0s infinite;
	background-color: #53a0fd;
	transform-origin: left center;
}

/* stylelint-disable-next-line @mrhenry/stylelint-mrhenry-attribute-selector-no-unknown */
[folder-rendered="folder-rendered"] .window--folder .window__content::before {
	display: none;
}

/* stylelint-disable-next-line @mrhenry/stylelint-mrhenry-attribute-selector-no-unknown */
[folder-rendered="folder-rendered"] .window--folder .window__content::after {
	display: none;
}

@keyframes RANDOM_LOADING_ANIMATION {
	0% {
		transform: scale3d(0.2, 1, 1) translateY(-50%);
	}

	10% {
		transform: scale3d(0.37, 1, 1) translateY(-50%);
	}

	20% {
		transform: scale3d(0.9, 1, 1) translateY(-50%);
	}

	30% {
		transform: scale3d(0.125, 1, 1) translateY(-50%);
	}

	40% {
		transform: scale3d(0.15, 1, 1) translateY(-50%);
	}

	50% {
		transform: scale3d(0.15, 1, 1) translateY(-50%);
	}

	60% {
		transform: scale3d(0.5, 1, 1) translateY(-50%);
	}

	70% {
		transform: scale3d(0.3, 1, 1) translateY(-50%);
	}

	80% {
		transform: scale3d(0.666, 1, 1) translateY(-50%);
	}

	90% {
		transform: scale3d(0.75, 1, 1) translateY(-50%);
	}

	100% {
		transform: scale3d(0.8, 1, 1) translateY(-50%);
	}
}

.window__video {
	display: block;
	width: 100%;
	height: auto;
}

.window__image {
	display: block;
	user-select: none;
	width: 100%;
	height: auto;
}

.window__mute {
	position: absolute;
	top: 50%;
	right: 1.25rem;
	transform: translateY(-50%);
}

@media (width >= 64rem) {
	.window__mute {
		right: var(--space-medium);
	}
}

.window-popover-window {
	display: none; /* display is set by js */
	position: absolute;
	width: 600px;
	height: 438px;
}

.window__meta {
	margin-top: 1.875rem;
}

@media (width >= 48rem) {
	.window__meta {
		display: none; /* Mobile only */
	}
}

.window__meta__title {
	display: block;
	text-align: center;
}

.window__meta__table {
	border: 0 solid transparent;
}

.window__meta__table td {
	border: 0 solid transparent;
	padding: 0;
	vertical-align: top;
}

.window__meta__table td:first-child {
	color: var(--color-orange);
	padding-right: 1.25rem;
	text-align: right;
}
