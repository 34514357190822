.video-background {
	overflow: hidden;
	position: relative;
	width: 100%;
	height: 100vh;
}

@media (max-aspect-ratio: 1/1) {
	.video-background {
		overflow: visible;
		height: 100%;
	}
}

.video-background__canvas {
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

@media (max-aspect-ratio: 1/1) {
	.video-background__canvas {
		display: block;
		height: 100vh;
	}
}

.video-background__video {
	top: 50%;
	left: 50%;
	width: auto;
	height: 100vh;
}

@supports (object-fit: cover) {
	.video-background__video {
		object-fit: cover;
		width: 100%;
	}
}

@media (max-aspect-ratio: 1/1) {
	.video-background__video {
		pointer-events: none;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: auto;
	}
}
