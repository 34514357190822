.tree-view {
	border-top: 1px solid rgb(0 0 0 / 0.1);
	box-sizing: border-box;
}

.tree-view__row {
	border-bottom: 1px solid rgb(0 0 0 / 0.1);
	box-sizing: border-box;
	display: block;
	overflow: hidden;
	width: 100%;
}

@media (--mq-up-to-small) {
	.tree-view__row {
		padding: 2rem 1.25rem;
	}
}

@media (--mq-from-small) {
	.tree-view__row {
		display: flex;
	}
}

@media (--mq-up-to-small) {
	.tree-view__column {
		text-align: center;
	}
}

@media (--mq-from-small) {
	.tree-view__column {
		display: flex;
		flex-basis: 50%;
		flex-grow: 0;
		flex-shrink: 0;
		justify-content: center;
		align-items: center;
		min-height: 18.75rem;
	}
}

@media (--mq-up-to-small) {
	.tree-view__column:not(:last-child) {
		margin-bottom: 2rem;
	}
}

@media (--mq-from-small) {
	.tree-view__column:not(:only-child):not(:last-child) {
		border-right: 1px solid rgb(0 0 0 / 0.1);
	}
}

@media (--mq-from-small) {
	.tree-view__row:nth-child(2) .tree-view__column {
		display: flex;
		flex-basis: 33.3333%;
	}
}

@media (--mq-from-small) {
	.tree-view__row:nth-child(1n + 3) .tree-view__column {
		display: flex;
		flex-basis: 25%;
	}
}

.tree-view__column__wrapper {
	box-sizing: border-box;
}

@media (--mq-from-small) {
	.tree-view__column__wrapper {
		padding: 3rem;
	}
}

@media (--mq-from-wide) {
	.tree-view__column__wrapper {
		padding-right: 5rem;
		padding-left: 5rem;
	}
}

@media (--mq-from-medium) and (width < 80rem) {
	.tree-view__column__wrapper > p {
		font-size: 1rem;
		line-height: 1.5;
	}
}

.tree-view__column__wrapper em {
	color: rgb(0 0 0 / 0.2);
	display: inline-block;
	font-style: normal;
	margin-right: 0.5rem;
}

@media (--mq-up-to-small) {
	.tree-view__column__wrapper em {
		display: block;
		margin-right: 0;
		margin-bottom: 0.5rem;
	}
}

.tree-view__column__wrapper img {
	display: block;
	margin: 0 auto;
	width: auto;
	max-width: 50%;
	height: auto;
}

@media (--mq-from-small) {
	.tree-view__column__wrapper img {
		max-width: 100%;
	}
}
