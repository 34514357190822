.start-page {
	background-color: var(--color-orange);
	min-height: 100vh;
}

.start-page__columns {
	column-count: 1;
	column-gap: 2rem;
	padding: 2rem 0;
}

@media (--mq-from-small) {
	.start-page__columns {
		column-count: 2;
		padding: 3rem 0;
	}
}

@media (--mq-from-medium) {
	.start-page__columns {
		column-count: 3;
	}
}

@media (--mq-from-wide) {
	.start-page__columns {
		padding: 5rem 0;
	}
}

@media (--mq-from-large) {
	.start-page__columns {
		column-count: 4;
		padding: 8rem 0;
	}
}

.start-page__list,
.start-page__list * {
	break-inside: avoid;
}

.start-page__list {
	box-sizing: border-box;
	/* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
	font-family: "MaisonNeueMono";
	margin-bottom: 2rem;
	padding: 0.75rem;
}

.start-page__list__heading {
	font-size: 1.25rem;
	margin-bottom: 0.5rem;
	text-align: right;
}

.start-page__list__heading a {
	text-decoration: none;
}

.start-page__list__heading:target::before {
	content: "";
	display: block;
	margin-top: -75px;
	pointer-events: none;
	height: 75px;
}

.start-page__list__link-list {
	padding-right: 1.5rem;
	padding-left: 2rem;
}

.start-page__list__link-list a {
	display: block;
	font-size: 1rem;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.start-page__list__link-list a [aria-hidden="true"] {
	/* this affects screenreaders, there is a visually hidden span with the same content without this transform */
	text-transform: lowercase;
}

.start-page__list:nth-child(2n + 0) {
	border-width: 2px;
}

.start-page__list:nth-child(2n + 1) {
	border-width: 3px;
}

.start-page__list:nth-child(4n + 0) {
	border-style: dotted;
}

.start-page__list:nth-child(4n + 1) {
	border-style: dashed;
}

.start-page__list:nth-child(4n + 2) {
	border-style: solid;
}

.start-page__list:nth-child(4n + 3) {
	border-style: double;
}

.start-page__list:nth-child(4n + 0) {
	border-top-color: #9013fe;
	border-left-color: #9013fe;
}

.start-page__list:nth-child(4n + 1) {
	border-top-color: cyan;
	border-left-color: cyan;
}

.start-page__list:nth-child(4n + 2) {
	border-top-color: #ffc0cb;
	border-left-color: #ffc0cb;
}

.start-page__list:nth-child(4n + 3) {
	border-top-color: blue;
	border-left-color: blue;
}

.start-page__list:nth-child(5n + 2) {
	border-right-color: #9013fe;
	border-bottom-color: #9013fe;
}

.start-page__list:nth-child(5n + 3) {
	border-right-color: cyan;
	border-bottom-color: cyan;
}

.start-page__list:nth-child(5n + 4) {
	border-right-color: #ffc0cb;
	border-bottom-color: #ffc0cb;
}

.start-page__list:nth-child(5n + 0) {
	border-right-color: blue;
	border-bottom-color: blue;
}

.start-page__list:nth-child(5n + 1) {
	border-right-color: green;
	border-bottom-color: green;
}

.start-page__list:nth-child(4n + 0) {
	background-color: #eeeeee;
}

.start-page__list:nth-child(4n + 1) {
	background-color: #efefef;
}

.start-page__list:nth-child(4n + 2) {
	background-color: #eeffee;
}

.start-page__list:nth-child(4n + 3) {
	background-color: #eeeeff;
}

.start-page__list:nth-child(4n + 0) li {
	list-style: lower-alpha;
}

.start-page__list:nth-child(4n + 1) li {
	list-style: lower-greek;
}

@counter-style fancy {
	system: cyclic;
	speak-as: lower-latin;
	symbols: ◎ ◉ ⦿ ❂ ✪ ⚙︎ ❄︎;
	suffix: " ";
}

.start-page__list:nth-child(4n + 2) li {
	list-style-type: decimal;
	list-style-type: fancy;
}

.start-page__list:nth-child(4n + 0) .start-page__list__link-list {
	padding-left: 2.75rem;
}

.start-page__list:nth-child(4n + 1) .start-page__list__link-list {
	padding-left: 2.75rem;
}

.start-page__list:nth-child(4n + 2) .start-page__list__link-list {
	padding-left: 2.5rem;
}
